export default function structuredClone(obj) {
  let clone;
  try {
    clone = window.structuredClone(obj);
  } catch (error) {
    //console.error(error);
    console.debug(error.message + ". Falling back to JSON stringify/parse.");
    clone = JSON.parse(JSON.stringify(obj));
  }
  return clone;
}
