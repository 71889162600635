import type { MonitioAPI } from "@root/types.api.local";
import type { AxiosInstance } from "axios";

export default (axios: AxiosInstance) => ({
  resolveFBUrl(url: string) {
    return axios.get(`/v1/documents/resolve-fb-url?url=${url}`);
  },
  logDocumentRedirect(viewId: string, documentId: string) {
    return axios.get(`/v1/documents/log-redirect/${viewId}/${documentId}`);
  },
  getDocument(viewId: string, articleId: string, alsoTeams = false) {
    return axios.get<MonitioAPI.SearchDocumentDTO>("/v1/documents", {
      params: {
        viewId: viewId,
        docId: articleId,
        alsoTeams: alsoTeams,
      },
    });
  },
  getDocumentBySourceItemIdAtOrigin(
    viewId: string,
    id: string,
    alsoTeams = false
  ) {
    return axios.get<MonitioAPI.SearchDocumentDTO>(
      "/v1/documents/by-sourceitemId",
      {
        params: {
          viewId: viewId,
          docId: id,
          alsoTeams: alsoTeams,
        },
      }
    );
  },
  getDocumentShareCode(viewId: string, documentId: string, url: string) {
    return axios.get<string>(
      `/v1/documents/article-share-code?viewId=${viewId}&documentId=${documentId}&url=${url}`
    );
  },
  getURLFromShareCode(code: string) {
    return axios.get<string>(
      `/v1/documents/url-from-share-code?shortened=${code}`
    );
  },
});
