import * as signalR from "@microsoft/signalr";
import { inject, KeepAlive } from "vue";
import storage from "@utils/tokenStorage";
import { useApi } from "@api/api";
import { delay } from "lodash-es";

const url = `${window._env_.VUE_APP_apiUrl}/signalr`;

export const signalRSymbol = Symbol("signalr");
export const createHubs = () => {
  const _eventTarget = new EventTarget();

  let _connection = null;
  let _retryIn = 1000;
  let _retryTimeout = null;
  const maxRetryIn = 60000;
  let _canReconnect = false;

  const _observes = new Map();
  const _connectionIdPromises = new Set();

  const { api } = useApi();

  const createConnection = () => {
    console.log(
      "%c Websocket connecting...",
      "color: #2b4473; font-size: normal"
    );
    clearTimeout(_retryTimeout);
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: false,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => storage.jwtToken?.token,
      })
      .configureLogging(signalR.LogLevel.None)
      .build();

    const dispatchWSEvent = (type, context, value) => {
      _eventTarget.dispatchEvent(
        new CustomEvent(type, { detail: { context, value } })
      );
    };
    // TODO: probably change the way this is invoked
    // change should not dispatch an event to context without showing were it came from
    connection.on("Changed", (context, value) => {
      dispatchWSEvent("changed", context, value);
    });
    connection.on("Delete", (context, value) => {
      dispatchWSEvent("delete", context, value);
    });
    connection.on("Locked", (context, value) => {
      dispatchWSEvent("lock", context, value);
    });
    connection.on("Notification", (value) => {
      _eventTarget.dispatchEvent(
        new CustomEvent("notification", { detail: value })
      );
    });
    connection.on("UserTracking", (context, value) => {
      dispatchWSEvent("userTracking", context, value);
    });
    connection.on("TaskStatusUpdate", (context, value) => {
      dispatchWSEvent("taskStatusUpdate", context, value);
    });

    connection.onclose(function (err) {
      if (_canReconnect) {
        if (err === undefined) {
          console.warn(
            `%c Websocket auth expired.`,
            "color: #fca903; font-size: normal"
          );
          delay(() => {
            //Delay this execution because withtout it this happens on window reload, and it might cause problems
            api.auth.refreshToken(storage.refreshToken).then(({ data }) => {
              storage.jwtToken = data;
              _retryIn = 1000;
              reconnect();
            });
          }, 300);
        } else {
          _eventTarget.dispatchEvent(
            new CustomEvent("state", {
              detail: {
                status: "closed",
                connectionState: connection.connectionState,
              },
            })
          );
          console.error(
            `%c Websocket closed.`,
            "color: #e31948; font-size: normal"
          );
          _retryIn = 1000;
          reconnect();
        }
      } else {
        console.info(
          `%c Websocket shutdown.`,
          "color: #e31948; font-size: normal"
        );
      }
    });

    connection
      .start()
      .then((val) => {
        _eventTarget.dispatchEvent(
          new CustomEvent("state", {
            detail: {
              status: "open",
              connectionState: connection.connectionState,
            },
          })
        );
        console.log(
          "%c Websocket connected.",
          "color: #4c9e54; font-size: normal"
        );

        setTimeout(() => {
          _observes.forEach(async (_, context) => {
            connection.invoke("Observe", context).catch(() => undefined);
          });
        }, 0);

        // A hack! Event if the handler on the server fails the connection starts as
        // started, we need to let an arbitrary time to settle and check the state
        setTimeout(() => {
          if (connection._connectionStarted) {
            _connectionIdPromises.forEach(({ res }) => {
              res(connection.connectionId);
            });
            _connectionIdPromises.clear();
          }
        }, 100);
      })
      .catch((err) => {
        _eventTarget.dispatchEvent(
          new CustomEvent("state", {
            detail: {
              status: "error",
              connectionState: null,
            },
          })
        );
        console.error(
          `%c Websocket failed. Retrying in ${_retryIn / 1000}s.`,
          "color: #e31948; font-size: normal"
        );

        _retryTimeout = setTimeout(reconnect, _retryIn);
        _retryIn += _retryIn;

        if (_retryIn > maxRetryIn) _retryIn = maxRetryIn;
      });

    return connection;
  };

  const reconnect = () => {
    if (_canReconnect) _connection = createConnection();
  };

  const customListenerMap = new WeakMap();
  const customCallbackFactory = (context, callback) => (event) => {
    if (event.detail?.contextId === context) callback.call(_eventTarget, event);
  };

  const hub = {
    addEventListener: _eventTarget.addEventListener.bind(_eventTarget),
    removeEventListener: _eventTarget.removeEventListener.bind(_eventTarget),
    /// An helper eventListener binder
    /// this way it's possible to add a listener
    /// to a context but filter the dispatch to only
    /// call the callback to a specific WS handler (action)
    addActionEventListener: (actionType, context, callback, options) => {
      const customCallback = customCallbackFactory(context, callback);
      customListenerMap.set(callback, customCallback);
      _eventTarget.addEventListener(actionType, customCallback, options);
    },
    removeActionEventListener: (actionType, callback) => {
      if (customListenerMap.has(callback)) {
        const customCallback = customListenerMap.get(callback);
        _eventTarget.removeEventListener(actionType, customCallback);
        customListenerMap.delete(callback);
      }
    },
    async observe(context) {
      if (_observes.has(context))
        _observes.set(context, _observes.get(context) + 1);
      else _observes.set(context, 1);

      if (_connection && _connection._connectionStarted) {
        await _connection.invoke("Observe", context);
      }
    },
    async release(context) {
      if (
        _connection &&
        _connection._connectionStarted &&
        _observes.has(context) &&
        _observes.get(context) > 0
      ) {
        _observes.set(context, _observes.get(context) - 1);
        await _connection.invoke("ReleaseObserve", context);
      }
    },
    async keepalive(activityId, metadata) {
      if (_connection && _connection._connectionStarted) {
        return await _connection.invoke("KeepAlive", activityId, metadata);
      }
    },
    async activityOpen(contextType, contextId, metadata) {
      if (_connection && _connection._connectionStarted) {
        return await _connection.invoke(
          "OpenActivity",
          contextType,
          contextId,
          metadata
        );
      }
    },
    async activityClose(activityId, metadata) {
      if (_connection && _connection._connectionStarted) {
        return await _connection.invoke("CloseActivity", activityId, metadata);
      }
    },
    init() {
      _retryIn = 1000;
      console.log(
        "%c Websocket initializing...",
        "color: #2b4473; font-size: normal"
      );
      _canReconnect = true;
      _connection = createConnection();
    },
    shutdown() {
      _canReconnect = false;
      if (_connection) _connection.stop();
      _observes.clear();
      _connectionIdPromises.forEach(({ _, rej }) => {
        rej();
      });
      _connectionIdPromises.clear();
    },
    getConnectionId() {
      const promise = new Promise((res, rej) => {
        if (_connection && _connection._connectionStarted) {
          res(_connection.connectionId);
        } else {
          _connectionIdPromises.add({ res, rej });
        }
      });
      return promise;
    },
  };

  Object.defineProperty(hub, "connectionId", {
    get() {
      return _connection && _connection.connectionId;
    },
  });

  return hub;
};

const hubs = createHubs();
export const registSignalR = (app) => {
  app.provide(signalRSymbol, hubs);
};
export const useSignalR = () => hubs;
