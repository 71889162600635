<template>
  <render />
  <label id="text_formater_entity_label" class="visually-hidden">
    {{ t("article.entity_actions") }}
  </label>
  <m-dropdown
    v-if="mentionEl"
    id="text_formater_entity"
    ref="dropdownRef"
    v-model:visible="openDropdown"
    labelledBy="text_formater_entity_label"
    :options="options"
    floating
    :parent="mentionEl"
    @update:selected="selectOpt"
    @mouseenter="keepDropdownOpen"
    v-click-outside="closeDropdown"
  />
</template>

<script setup>
import { h, watch, ref, computed, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import useDropdown from "@hooks/useDropdown";
import useCreateMentionModal from "@components/modals/MCreateMention/useCreateMentionModal";

import MDropdown from "@components/MDropdown.vue";
import MTextEntityLink from "@components/MTextEntityLink.vue";
import MTranscriptionSegment from "@components/MTranscriptionSegment.vue";
import { isEmpty } from "lodash-es";
import MLegixLink from "./MLegixLink.vue";
const props = defineProps({
  text: String,
  format: Array,
  langDirection: String,
  canEditEntities: Boolean,
  showTranslation: Boolean,
  article: Object,
  entityWikidata: Array,
  selectedLang: String,
  wrapperClass: { type: String, required: false, default: "m-text-formatter" },
});

const emit = defineEmits(["entity-updated"]);

const { t } = useI18n();

const { openDropdown, keepDropdownOpen, closeDropdown } = useDropdown();
const { open: openCreate, close: closeCreate } = useCreateMentionModal();

const dropdownRef = ref(null);
const newMention = ref(null);
const mentionOffsets = ref({});
const mentionEl = ref(null);
const options = computed(() => {
  const result = [];

  if (props.article.language == props.selectedLang)
    result.push({ value: "create", label: t("article.entity_create") });
  return result;
});

const setSelection = (e) => {
  if (!props.canEditEntities) return; //Is trying to see entity dropdown || Cannot edit entities, so return;
  if (window.getSelection().toString() === "") return; // Nothing really selected return;

  const textFormattedEl = document.getElementsByClassName(
    props.wrapperClass
  )?.[0];
  if (textFormattedEl.contains(e.target)) {
    const userSelectedRange = window.getSelection().getRangeAt(0);
    const userSelectedString = userSelectedRange.toString();

    const textFormatterNode = document.getElementsByClassName(
      props.wrapperClass
    )[0];
    const shadowRange = new Range(); //new range to calc the offsets relative to the whole text
    /** Next we calculate a virtual selection of the entire text content from the begging to the user selected end */
    shadowRange.setStart(textFormatterNode, 0);
    shadowRange.setEnd(
      userSelectedRange.endContainer,
      userSelectedRange.endOffset
    );
    const shadowRangeStringSize = shadowRange.toString().length;

    /** The end Offset is simple, its just the size of the virtual selection because it ends in the same place the user slected */
    mentionOffsets.value.endOffset = shadowRangeStringSize;
    /** To get the start position just subtract the lenght of the user selected text */
    mentionOffsets.value.startOffset =
      shadowRangeStringSize - userSelectedString.length;

    newMention.value = userSelectedRange.toString();
    mentionEl.value = window.getSelection().getRangeAt(0);
    openDropdown.value = true;
  }
};

const selectOpt = async (opt) => {
  switch (opt.value) {
    case "create":
      openCreate(
        newMention,
        mentionOffsets.value.startOffset,
        mentionOffsets.value.endOffset,
        props.article
      )
        .then((data) => {
          emit("entity-updated", data?.result);
        })
        .catch(() => closeCreate);
      break;
    default:
      break;
  }
};

const clearSelection = () => {
  mentionEl.value = null;
};

onMounted(() => {
  render();

  const container = document.getElementsByClassName("m-container")?.[0];
  container?.addEventListener("scroll", clearSelection);
  container?.addEventListener("copy", tryOverrideCopyPaste);
  document.addEventListener("mouseup", setSelection);
  document.addEventListener("keyup", setSelection);
});

onUnmounted(() => {
  const container = document.getElementsByClassName("m-container")?.[0];
  container?.removeEventListener("scroll", clearSelection);
  container?.removeEventListener("copy", tryOverrideCopyPaste);
  document.removeEventListener("mouseup", setSelection);
  document.removeEventListener("keyup", setSelection);
});

/**
 *
 * @param evt {Event}
 */
const tryOverrideCopyPaste = (evt) => {
  const text = window
    .getSelection()
    .toString()
    .replace(/(?<!\.)(\n|\r)/gm, ""); //Only keep paragraphs that are after a dot(.)
  // 💥 By the time of writing this doesnt work in firefox (the regex negative lookbehind)

  evt.clipboardData.setData("text/plain", text);
  evt.preventDefault();
  return;
};

watch(
  [props.format, props.entityWikidata],
  ([a, b]) => {
    render();
  },
  { deep: true }
);

const splitList = (list, position) => {
  if (position == 0) return 0;
  for (let i = 0; i < list.length; i++) {
    const el = list[i];
    if (typeof el !== "string") continue;
    if (position <= el.length) {
      const before = el.substring(0, position);
      const after = el.substring(position);

      if (before.length != 0) {
        list[i] = after;
        list.splice(i, 0, before);
        return i + 1;
      } else return i;
    }
    position -= el.length;
  }
  return list.length;
};

/** @description takes in the text and the format and transforms them into html */
const formatText = (text, format) => {
  if (!text || !format) return "";
  const formats = [].concat(format);
  formats.sort((a, b) => b.ord - a.ord);

  const list = [text];
  let ids = 0;
  formats.forEach((el) => {
    if (!el) return;
    el.id = ids++;
    if (el.start < el.end) {
      const startidx = splitList(list, el.start);
      list.splice(startidx, 0, {
        type: "start",
        tag: el.tag,
        id: el.id,
        el: el,
      });
      const endidx = splitList(list, el.end);
      list.splice(endidx, 0, { type: "end", tag: el.tag, el: el });
    } else {
      const idx = splitList(list, el.start);
      list.splice(
        idx,
        0,
        { type: "start", tag: el.tag, id: el.id, el: el },
        { type: "end", tag: el.tag, el: el }
      );
    }
  });
  const temp = document.createElement("div");
  temp.innerHTML = list
    .map((f) => {
      switch (f.type) {
        case "start": {
          let result = "";
          result += `<${f.tag} data-original-id="${f.id}"`;
          if (f.tag == "img" && f.el?.src) result += `src="${f.el?.src}"`;
          if (f.el?.att && !isEmpty(f.el.att)) {
            result += ` ${Object.entries(f.el?.att)
              .map(([key, value]) => `${key}="${value}"`)
              .join("")}`;
          }
          result += ">";
          return result;
        }
        case "end":
          if (f.tag == "br") return ""; // If br tag we dont close them, or they will get duplicated in html
          return `</${f.tag}>`;
        default:
          return f;
      }
    })
    .join("");

  formats.forEach((f) => {
    if (!f) return;
    const el = temp.querySelector(`[data-original-id="${f.id}"]`);
    if (!el) {
      //console.log(formats, list);
      //console.log(temp, f.id);
      return;
    }
    el.data = f;
  });

  return temp;
};

/** @description takes in the html previously done by @ref formatText function and converts it into a vue vNode so it can be rendered */
const transformToVNode = (dom) => {
  //const root = document.createElement(d)
  if (dom.nodeType === 3) return dom.textContent;
  else if (dom.nodeType === 1) {
    let vchildren;
    if (dom.childNodes) {
      /*  console.log("parent", dom);
      console.log("childNOdes", dom.childNodes); */
      vchildren = [...dom.childNodes]
        .map((f) => transformToVNode(f))
        .filter((f) => f);
    } else vchildren = [];

    let itemProps = {
      originalStartPos: dom.dataset.originalstartpos,
      originalEndPos: dom.dataset.originalendpos,
      class: dom?.data?.class,
      dir: props.langDirection,
    };

    if (dom.nodeName == "ENTITY-LINK") {
      const entiWikiData = props.entityWikidata.find(
        (x) => x.id == dom.data.entity.key
      );
      dom.data.entity.language = props.article.language;
      if (entiWikiData & (typeof dom.data.entity.uri != "string"))
        dom.data.entity.uri = entiWikiData.page;
    }

    if (dom?.data?.att && !isEmpty(dom.data.att)) {
      itemProps = { ...itemProps, ...dom.data.att };
    }

    if (dom.nodeName == "ENTITY-LINK") {
      const wikidata = props.entityWikidata.find(
        (x) =>
          x.id == dom.data?.entity?.id ||
          x.id == dom.data?.entity?.label ||
          x.label == dom.data?.entity?.label ||
          x.label == dom.data?.entity?.baseForm
      );
      if (wikidata) {
        itemProps.entity = dom.data;
        itemProps.entity.wikidataUri = wikidata;
      } else itemProps.entity = dom.data;
      itemProps.canEditEntities = props.canEditEntities;
      itemProps.article = props.article;
      itemProps.onEntityUpdated = (doc) => emit("entity-updated", doc);
      return h(MTextEntityLink, itemProps, {
        default: () => vchildren,
      });
    } else if (dom.nodeName == "TRANSCRIPTION-SEGMENT") {
      itemProps.startTime = dom.data.startTime;
      itemProps.endTime = dom.data.endTime;
      return h(MTranscriptionSegment, itemProps, {
        default: () => vchildren,
      });
    } else if (dom.nodeName == "LEGIX-LINK") {
      itemProps.startTime = dom.data.startTime;
      itemProps.endTime = dom.data.endTime;
      itemProps.id = dom.data.id;
      itemProps.entity = dom.data.entity;
      itemProps.article = props.article;

      return h(MLegixLink, itemProps, {
        default: () => vchildren,
      });
    } else if (dom.nodeName === "TABLE") {
      return h("div", { class: "table-overflow-wrapper" }, [
        h(dom.nodeName, itemProps, {
          default: () => vchildren,
        }),
      ]);
    } else {
      if (dom.nodeName == "IMG" && !itemProps.src && dom.data?.src) {
        itemProps.src = dom.data.src;
      } else {
        return h(dom.nodeName, itemProps, {
          default: () => vchildren,
        });
      }
    }
  } else return null;
};

const formattedDOM = computed(() => formatText(props.text, props.format));
const render = () => {
  //console.log(formattedDOM.value);
  const vNodes = [transformToVNode(formattedDOM.value)];
  return h(
    "div",
    {
      class: props.wrapperClass,
    },
    {
      default: () => vNodes,
    }
  );
};
</script>

<style lang="scss">
@use "@stylesheets/scss/components/textformatter";
</style>

<style scoped>
/* eslint-disable no-emtpy */

.text-formatter {
  position: relative;
}

:deep(.text-formatter *) {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.125rem;
  line-height: 1.75;
  color: hsl(200, 16%, 7%);
}

:deep(.m-text-formatter__img) {
  max-width: 100%;
}

/* html entities */
.text-formatter .CM_INLINE {
  display: inline;
}
.text-formatter .CM_BLOCK {
  display: block;
}
/*
.text-formatter .CM_OBJECT {
}
.text-formatter .CM_IMG {
}
.text-formatter .CM_PARAM {
}
.text-formatter .CM_OPT {
}
.text-formatter .CM_OMITST {
}
.text-formatter .CM_DEFLIST {
}
.text-formatter .CM_FRAMES {
}
.text-formatter .CM_LIST {
}
.text-formatter .CM_FIELD {
}
*/
.text-formatter .CM_TABLE {
  display: table;
}
.text-formatter .CM_NO_INDENT {
  text-indent: none;
}
.text-formatter .CM_HEADING {
  margin-top: 30px;
  padding: 15px 0 5px;
}

.text-formatter .doc_el_h3.CM_HEADING,
.text-formatter .doc_el_h4.CM_HEADING,
.text-formatter .doc_el_h5.CM_HEADING,
.text-formatter .doc_el_h6.CM_HEADING {
  margin-bottom: 0 !important;
}
.text-formatter .CM_ROWGRP {
  display: table-row-group;
}
.text-formatter .CM_ROW {
  display: table-row;
}
.text-formatter .CM_EMPTY {
  display: none;
}

.CM_BLOCK:empty,
.CM_INLINE:empty {
  display: none !important;
}

.CM_BLOCK + .CM_INLINE {
  display: block;
}

/* end html entities */

/* html tags */

.text-formatter > div > .doc_el_div > .doc_el_div {
  margin-bottom: 20px;
}

/*
.text-formatter .doc_el_a {
}
.text-formatter .doc_el_abbr {
}
.text-formatter .doc_el_acronym {
}
.text-formatter .doc_el_applet {
}
.text-formatter .doc_el_base {
}
.text-formatter .doc_el_basefont {
}
.text-formatter .doc_el_big {
}
.text-formatter .doc_el_button {
}
.text-formatter .doc_el_dir {
}
.text-formatter .doc_el_font {
}
.text-formatter .doc_el_frame {
}
.text-formatter .doc_el_frameset {
}
.text-formatter .doc_el_html {
}
.text-formatter .doc_el_iframe {
}
.text-formatter .doc_el_img {
}
.text-formatter .doc_el_input {
}
.text-formatter .doc_el_isindex {
}
*/
.text-formatter .doc_el_address {
  font-style: italic;
}
.text-formatter .doc_el_italic {
  font-style: italic;
  display: inline-block;
}
.text-formatter .doc_el_area {
  display: none;
}
.text-formatter .doc_el_p > .doc_el_b,
.text-formatter .doc_el_p > .doc_el_strong {
  padding-bottom: 0 !important;
}
.text-formatter .doc_el_b,
.text-formatter .doc_el_strong,
.text-formatter .doc_el_strong * {
  font-weight: 700;
}
.text-formatter .doc_el_bdo {
  unicode-bidi: bidi-override;
}
.text-formatter .doc_el_blockquote {
  display: block;
  margin: 30px 35px;
  padding: 10px 20px 1px;
  border-left: 5px solid #3b68bdb9;
}

.text-formatter .doc_el_blockquote .doc_el_p {
  margin-bottom: 10px !important;
}
.text-formatter .doc_el_body {
  margin: 8px;
}
.text-formatter .doc_el_body:focus {
  outline: none;
}
.text-formatter .doc_el_br {
  margin-bottom: 10px;
  display: block !important;
}
.text-formatter .doc_el_caption {
  display: table-caption;
  text-align: center;
}
.text-formatter .doc_el_center {
  text-align: center;
}
.text-formatter .doc_el_cite {
  font-style: italic;
}
.text-formatter .doc_el_code {
  font-family: monospace;
}
.text-formatter .doc_el_col {
  display: table-column;
}
.text-formatter .doc_el_colgroup {
  display: table-column-group;
}
.text-formatter .doc_el_dd {
  margin-left: 15px;
  margin-right: 15px;
}
.text-formatter .doc_el_del {
  text-decoration: line-through;
}
.text-formatter .doc_el_dfn {
  font-style: italic;
}
.text-formatter .doc_el_div {
  margin-bottom: 10px;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 400;
  /* line-height: 1.5rem; */
  letter-spacing: 0;
}
.text-formatter .doc_el_dl {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}
.text-formatter .doc_el_dt {
  margin-top: 10px;
  margin-bottom: 2px;
  font-weight: 500;
}
.text-formatter .doc_el_em {
  font-style: italic;
}
.text-formatter .doc_el_fieldset {
  margin-left: 2px;
  margin-right: 2px;
  padding-top: 0.35em;
  padding-bottom: 0.625em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  border: 2px groove;
}
.text-formatter .doc_el_form {
  margin-top: 0em;
}
.text-formatter .doc_el_h1,
.text-formatter .doc_el_h1 * {
  /* font-size: 1.625rem;
  line-height: 2.6875rem;
  font-weight: 900; */
  font-size: 1.25rem;
  line-height: 2.0625rem;
  font-weight: 700;
  letter-spacing: 0;
}

.text-formatter .doc_el_h1 {
  margin-bottom: 20px;
}

.text-formatter .doc_el_h2,
.text-formatter .doc_el_h2 * {
  /* font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 900; */
  font-size: 1.1875rem;
  line-height: 2rem;
  font-weight: 700;
  letter-spacing: 0;
}

.text-formatter .doc_el_h1 {
  margin-bottom: 20px;
}

.text-formatter .doc_el_h3,
.text-formatter .doc_el_h3 * {
  font-size: 1.125rem;
  line-height: 1.9375rem;
  font-weight: 700;
  letter-spacing: 0;
}
.text-formatter .doc_el_h3 {
  margin-bottom: 10px;
}
.text-formatter .doc_el_h4,
.text-formatter .doc_el_h4 * {
  font-size: 1.1875rem;
  line-height: 2.0625rem;
  font-weight: 400;
}

.text-formatter .doc_el_4 {
  margin-bottom: 5px;
}
.text-formatter .doc_el_h5,
.text-formatter .doc_el_h5 * {
  font-size: 1.1875rem;
  /* line-height: 1.875rem; */
  font-weight: 400;
}
.text-formatter .doc_el_h6 {
  margin-bottom: 5px;
}
.text-formatter .doc_el_h6,
.text-formatter .doc_el_h6 * {
  font-size: 1rem;
  line-height: 1.6875rem;
  font-weight: 400;
}
.text-formatter .doc_el_h6 {
  margin-bottom: 10px;
}
.text-formatter .doc_el_head {
  display: none;
}
.text-formatter .doc_el_hr {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

.text-formatter .doc_el_html:focus {
  outline: none;
}
.text-formatter .doc_el_i {
  font-style: italic;
}
.text-formatter .doc_el_xref {
  display: inline-block;
}
.text-formatter .doc_el_iframe:focus {
  outline: none;
}
.text-formatter .doc_el_iframe[seamless] {
  display: block;
}

.text-formatter .doc_el_ins {
  text-decoration: underline;
}
.text-formatter .doc_el_kbd {
  font-family: monospace;
}
.text-formatter .doc_el_label {
  cursor: default;
}
.text-formatter .doc_el_legend {
  padding-left: 2px;
  padding-right: 2px;
  border: none;
}
.text-formatter .doc_el_li::before {
  content: "";
  width: 4px;
  height: 4px;
  position: absolute;
  margin-left: -15px;
  margin-top: 10px;
  background-color: #3b68bdb9;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.text-formatter .doc_el_link {
  display: none;
}
/*
.text-formatter .doc_el_listing {
}
.text-formatter .doc_el_map {
}
.text-formatter .doc_el_menu {
}
.text-formatter .doc_el_meta {
}
.text-formatter .doc_el_noframes {
}
.text-formatter .doc_el_noscript {
}
.text-formatter .doc_el_object {
}
.text-formatter .doc_el_optgroup {
}
.text-formatter .doc_el_option {
}
*/
.text-formatter .doc_el_object:focus {
  outline: none;
}
.text-formatter .doc_el_ol {
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

.text-formatter .doc_el_p {
  margin-bottom: 10px;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 400;
  /* line-height: 1.875rem; */
  letter-spacing: 0;
}
.text-formatter .doc_el_p * {
  font-size: 1.125rem;
  /* line-height: 1.875rem; */
  letter-spacing: 0;
}
.text-formatter .doc_el_param {
  display: none;
}
.text-formatter .doc_el_pre {
  font-family: monospace;
  white-space: pre;
  margin: 1em 0;
}
.text-formatter .doc_el_q:before {
  content: open-quote;
}
.text-formatter .doc_el_q:after {
  content: close-quote;
}
/*
.text-formatter .doc_el_plaintext {
}
.text-formatter .doc_el_q {
}
.text-formatter .doc_el_rb {
}
.text-formatter .doc_el_rbc {
}
.text-formatter .doc_el_rp {
}
.text-formatter .doc_el_rt {
}
.text-formatter .doc_el_rtc {
}
.text-formatter .doc_el_ruby {
}
.text-formatter .doc_el_script {
}
.text-formatter .doc_el_select {
}
.text-formatter .doc_el_span {
}
.text-formatter .doc_el_ref {
}
.text-formatter .doc_el_strike {
}
.text-formatter .doc_el_style {
}
*/
.text-formatter .doc_el_s {
  text-decoration: line-through;
}
.text-formatter .doc_el_samp {
  font-family: monospace;
}

.text-formatter .doc_el_small {
  font-size: smaller;
}

.text-formatter .doc_el_sub {
  vertical-align: sub;
  font-size: smaller;
}
.text-formatter .doc_el_sup {
  vertical-align: super;
  font-size: smaller;
}

.text-formatter .doc_el_table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray;
}
.text-formatter .doc_el_tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.text-formatter .doc_el_td {
  display: table-cell;
  vertical-align: inherit;
}
.text-formatter .doc_el_tfoot {
  display: table-footer-group;
  vertical-align: middle;
  border-color: inherit;
}
.text-formatter .doc_el_th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: center;
}
.text-formatter .doc_el_thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
.text-formatter .doc_el_tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.text-formatter .doc_el_u {
  text-decoration: underline;
}
.text-formatter .doc_el_ul {
  list-style-type: disc;
  margin: 15px 0;
  padding-left: 40px;
}
.text-formatter .doc_el_var {
  font-style: italic;
}
.text-formatter .doc_el_embed:focus {
  outline: none;
}
/*
.text-formatter .doc_el_textarea {
}
.text-formatter .doc_el_title {
}
.text-formatter .doc_el_tt {
}
.text-formatter .doc_el_xmp {
}
.text-formatter .doc_el_nextid {
}
.text-formatter .doc_el_align {
}
.text-formatter .doc_el_bgsound {
}
.text-formatter .doc_el_blink {
}
.text-formatter .doc_el_comment {
}
.text-formatter .doc_el_embed {
}
.text-formatter .doc_el_ilayer {
}
.text-formatter .doc_el_keygen {
}
.text-formatter .doc_el_layer {
}
.text-formatter .doc_el_marquee {
}
.text-formatter .doc_el_multicol {
}
.text-formatter .doc_el_nobr {
}
.text-formatter .doc_el_noembed {
}
.text-formatter .doc_el_nolayer {
}
.text-formatter .doc_el_nosave {
}
.text-formatter .doc_el_server {
}
.text-formatter .doc_el_servlet {
}
.text-formatter .doc_el_spacer {
}
.text-formatter .doc_el_wbr {
}
.text-formatter .doc_el_article {
}
.text-formatter .doc_el_aside {
}
.text-formatter .doc_el_audio {
}
*/
.text-formatter .doc_el_canvas {
  height: 150px;
  width: 300px;
}
.text-formatter .doc_el_datalist {
  display: none;
}
.text-formatter .doc_el_figcaption {
  display: none;
}
.text-formatter .doc_el_figure {
  display: none;
}
.text-formatter .doc_el_mark {
  color: black;
  display: inline;
}
/*
.text-formatter .doc_el_command {
}
.text-formatter .doc_el_details {
}
.text-formatter .doc_el_footer {
}
.text-formatter .doc_el_header {
}
.text-formatter .doc_el_hgroup {
}
.text-formatter .doc_el_meter {
}
.text-formatter .doc_el_nav {
}
.text-formatter .doc_el_output {
}
.text-formatter .doc_el_progress {
}
.text-formatter .doc_el_section {
}
.text-formatter .doc_el_source {
}
.text-formatter .doc_el_summary {
}
.text-formatter .doc_el_track {
}
.text-formatter .doc_el_video {
}
*/
.text-formatter .doc_el_time {
  font-size: 0.8125rem;
  font-weight: 400;
  /* line-height: 1.6; */
  color: #62727dd0;
}
/* end html tags */
</style>
