/**
 * File with common code for sharing between article related views and components
 */

import { computed, ref, unref } from "vue";

import { useApi } from "@api/api";
import { useI18n } from "vue-i18n";
import { useViewsStore } from "@root/store/modules/views";
import { useAlertsStore } from "@root/store/modules/alerts";

export default (viewId) => {
  /** 🚨 Type and null checking for safety and debugging 🚨  */
  if (!viewId)
    throw new Error("Can't initialize useArticle hook without a viewId");
  viewId = unref(viewId);

  const viewsStore = useViewsStore();
  const alertsStore = useAlertsStore();
  const { api } = useApi();
  const { t } = useI18n();

  /**
   * Function that tells the store to update the view count,
   * and comunicates with the api to request the document read
   * @param {*} article
   * @returns {Promise} Its basicly the raw http request
   */
  const readArticle = async (article) => {
    if (article.isRead) return; //No need to make a request, article is already marked as read
    const response = await api.search.updateDocumentReadStatus(
      "read",
      viewId,
      article.id,
      article.index
    );
    if (response.status != 200) return;
    viewsStore.incrementViewArticleRead();
    article.isRead = true;
  };

  /**
   * Function that tells the store to update the view count,
   * and comunicates with the api to request the document unread
   * @param {*} article
   * @returns {Promise} Its basicly the raw http request
   */
  const unreadArticle = async (article) => {
    if (!article.isRead) return; //No need to make a request, article is already marked as unread
    const response = await api.search.updateDocumentReadStatus(
      "unread",
      viewId,
      article.id,
      article.index
    );
    if (response.status != 200) return;
    article.isRead = false;
    viewsStore.decrementViewArticleRead();
  };

  /**
   * Function to be used when you emit and event to change the read status
   * @param {boolean} status
   */
  const changeReadStatus = async (article, status) => {
    if (article.isRead == status) return; //This will do nothing unless wasting api calls, returning.
    if (status == true) readArticle(article);
    if (status == false) unreadArticle(article);
  };

  /**
   * @param {import("@root/types.api.local").MonitioAPI.SearchDocumentDTO} article
   * @param {unknown} emit
   */
  const saveArticle = async (article, emit = null) => {
    console.log("saveArticle");

    const response = await api.search.saveArticle(
      unref(viewId),
      article.id,
      article.date
    );
    if (response.status != 200) {
      alertsStore.add({
        type: "toast",
        variant: "error",
        message: t("general.alerts.toast.unableToSaveArticle", {
          name: article.title,
        }),
      });
      throw "A problem occured while saving this article";
    }
    article.isSaved = true;
    /** Emit event (key, value) */
    if (emit) {
      emit("update:article", "isSaved", true);
      alertsStore.add({
        type: "toast",
        variant: "save",
        message: t("general.alerts.toast.savedArticle", {
          name: article.title,
        }),
      });
    }
  };

  /**
   * @param {import("@root/types.api.local").MonitioAPI.SearchDocumentDTO} article
   * @param {unknown} emit
   */
  const unSaveArticle = async (article, emit = null) => {
    const response = await api.search.unsaveArticle(
      unref(viewId),
      article.id,
      article.index
    );
    if (response.status != 200) {
      alertsStore.add({
        type: "toast",
        variant: "error",
        message: t("general.alerts.toast.unableToUnsaveArticle", {
          name: article.title,
        }),
      });
      throw "A problem occured while unsaving this article";
    }
    article.isSaved = false;
    /** Emit event (key, value) */
    if (emit) emit("update:article", "isSaved", false);
  };

  return {
    readArticle,
    saveArticle,
    unreadArticle,
    unSaveArticle,
    changeReadStatus,
  };
};
