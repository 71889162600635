<template>
  <div
    v-if="entity"
    :id="`m_entity_link_${id}`"
    ref="wrapperRef"
    class="m-entity-link"
    :class="{ 'non-clickable': !canEditEntities }"
    @mouseenter="keepDropdownOpen"
    @mouseleave="closeDropdown"
  >
    <div
      ref="entityRef"
      :id="`m_entity_link_${id}_label`"
      :data-entity="entity?.entity?.key ?? entity?.entity?.label"
      :data-tooltip-content="JSON.stringify(filterBuild)"
      :data-tooltip-template="filterBuild.image ? 'badge' : 'badgeLoading'"
      data-tooltip-position="dynamic"
      tabindex="0"
      aria-controls="text_formater_entity"
      :aria-expanded="openDropdown"
      aria-haspopup="listbox"
      class="m-entity-link__underline"
      :class="{ '--a11y': appStore.a11y }"
      :style="underlinePosition"
      @mouseover.stop="focusMention = true"
      @mouseleave.stop="focusMention = false"
      @focus="focusMention = true"
      @blur="focusMention = false"
      @click.stop="openDropdown = true"
      @keyup.up="openDropdown = true"
      @keyup.down.exact="openDropdown = true"
      @keyup.alt.down="openDropdown = true"
      @keyup.home="openDropdown = true"
      @keyup.end="openDropdown = true"
      @keyup.space="openDropdown = true"
      @keyup.enter="openDropdown = true"
      @keyup.esc="closeDropdown"
    >
      <slot></slot>
    </div>
    <label :id="`m_entity_link_${id}_label`" class="visually-hidden">
      {{ t("article.entity_actions") }}
    </label>
    <m-dropdown
      v-if="namespace === 'wikidata'"
      :id="`m_entity_link_${id}`"
      ref="dropdownRef"
      v-model:visible="openDropdown"
      :labelledBy="`m_entity_link_${id}_label`"
      :options="options"
      floating
      :parent="wrapperRef"
      @update:selected="selectOpt"
      @mouseenter="keepDropdownOpen"
      @mouseleave="closeDropdown"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@root/store/app";
import base_image from "@assets/images/monitio_empty.png";
import useDropdown from "@hooks/useDropdown";

import MDropdown from "@components/MDropdown.vue";
import useEditMentionModal from "./modals/MEditMention/useEditMentionModal";
import { watch } from "vue";
import {
  encodeQueryObject,
  useFiltersStore,
} from "@root/store/modules/filters";
import structuredClone from "@root/utils/structuredClone";
import { useRoute, useRouter } from "vue-router";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

const { t, te } = useI18n();

const props = defineProps({
  entity: Object,
  canEditEntities: Boolean,
  article: Object,
});

const route = useRoute();
const router = useRouter();
const appStore = useAppStore();
const filtersStore = useFiltersStore();
const workspacesStore = useWorkspacesStore();

const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);

const emit = defineEmits(["entity-updated"]);

const { open: openEdit, close: closeEdit } = useEditMentionModal();
const { openDropdown, keepDropdownOpen, closeDropdown } = useDropdown();

/** @type {import("vue").Ref<HTMLElement>} */
const wrapperRef = ref(null);
/** @type {import("vue").Ref<HTMLElement>} */
const entityRef = ref(null);
const focusMention = ref(false);

const blurMention = () => {
  focusMention.value = false;
  closeDropdown();
};

const id = computed(() => {
  return `${props.entity?.entity?.key ?? props.entity?.entity?.label}_${
    props.entity?.startPosition?.offset
  }`;
});

const namespace = computed(() => props.entity.entity.namespace);
const isNILEntity = computed(() => props.entity.entity?.label?.includes("NIL"));
const label = computed(
  () => props.entity.wikidataUri?.label || props.entity.entity.label
);

const type = computed(
  () => /* props.entity.ner_type */ props.entity.entity.type
);

const checkForArabicLanguage = (txt) => {
  const arabicRegex = /[\u0600-\u06FF]/;
  return arabicRegex.test(txt) ? "rtl" : null;
};

const filterBuild = computed(() => {
  let image = null;
  if (props.entity.wikidataUri?.image) {
    image = props.entity.wikidataUri.image;
  }

  const computedType = isNILEntity.value
    ? "nil_" + type.value.replace("nil_", "")
    : type.value;

  return {
    dir: checkForArabicLanguage(label.value),
    label: label.value,
    image: image,
    type: te(`general.facets.${computedType}`)
      ? t(`general.facets.${computedType}`)
      : computedType,
    id: id.value,
  };
});

const badgeId = ref("");

const underlineN = ref(1);
watch(
  () => entityRef.value,
  (element) => {
    if (!element) return;
    /**
     * @param {HTMLElement} el
     * @returns {number}
     */
    function getUnderlines(el) {
      if (!el.hasChildNodes()) return 0;
      const underlinedNodes = {};
      // Go through the inner html and look for underlines (other MTextEntityLinks)
      for (let i = 0; i < el.children.length; i++) {
        const c1 = el.children?.item(i);
        if (!c1) continue;
        for (let idx = 0; idx < c1.children.length; idx++) {
          const c2 = c1.children.item(idx);
          if (!c2) continue;
          if (c2.className?.includes("underline"))
            underlinedNodes[c2.id] = getUnderlines(c2);
        }
      }

      return underlinedNodes;
    }

    // The result is basicly a tree that represents the DOM almost, but only regarding MTextEntities
    const tree = getUnderlines(element);

    //Get the max generations of the tree now
    function depthOf(object) {
      let level = 1;
      for (const key in object) {
        if (!Object.hasOwnProperty.call(object, key)) continue;

        if (typeof object[key] == "object") {
          const depth = depthOf(object[key]) + 1;
          level = Math.max(depth, level);
        }
      }
      return level;
    }

    underlineN.value = depthOf(tree);
  },
  { immediate: true }
);

const underlinePosition = computed(() => {
  const n = 2 * underlineN.value - 2;
  let style = `--n: ${n}px; color: #000;`;
  if (focusMention.value) {
    style +=
      "background: linear-gradient(to top, transparent var(--n), rgba(184, 205, 244, 1) var(--n), rgba(184, 205, 244, 1) calc(var(--n) + 1px), rgba(184, 205, 244, 0.4) calc(var(--n) + 1px));";
  } else {
    style +=
      "background: linear-gradient(to top, transparent var(--n), rgba(184, 205, 244, 1) var(--n), rgba(184, 205, 244, 1) calc(var(--n) + 1px), transparent calc(var(--n) + 1px));";
  }

  if (n > 4) {
    style += `padding-bottom: ${n - 4}px;`;
  }

  return style;
});

const options = computed(() => {
  const options = [];
  if (
    props.entity?.entity?.uri ||
    (props.entity.wikidataUri && props.entity.wikidataUri?.image != base_image)
  ) {
    options.push("open");
  }
  if (props.canEditEntities) options.push("edit");
  if (!props.entity?.entity) options.push("none");
  if (!props.entity.entity?.type?.includes("legix")) options.push("search");

  return options.map((m) => ({
    value: m,
    label: t(`article.entity_${m}`),
  }));
});

const goToLink = (link) => {
  if (link) {
    let cleanLink = link.split("?amp;utm_")[0];
    const hasParams = cleanLink.split("?")[1];

    if (hasParams) cleanLink = `${cleanLink}&`;
    else cleanLink = `${cleanLink}?`;

    window.open(`${cleanLink}utm_source=monitio`, "_blank");
  }
};

const selectOpt = async (opt) => {
  switch (opt.value) {
    case "open":
      if (props.entity.entity?.uri) goToLink(props.entity.entity.uri);
      else if (props.entity.wikidataUri?.page) {
        goToLink(props.entity.wikidataUri?.page);
      }
      break;
    case "edit":
      openEdit("edit", props.entity, props.article)
        .then((data) => {
          emit("entity-updated", data?.result);
        })
        .catch(() => closeEdit);
      break;
    case "search": {
      let propertyType = props.entity.entity.type;
      if (props.entity.entity.namespace === "wikidata") propertyType += "_qid";
      /** @type {import("@root/types").Monitio.URLQueryObject} */
      const qObj = structuredClone(filtersStore.queryObject);

      qObj.dateRestriction = {
        isRelative: true,
        timeFrame: "all",
      };
      qObj.filters = [
        {
          facets: [
            {
              value: propertyType,
              query: [
                {
                  value: props.entity.entity.key,
                  label: props.entity.entity.label,
                  propertyType,
                },
              ],
              mode: "singleType",
            },
          ],
        },
      ];
      router.push({
        name: "articles",
        params: {
          viewId: viewId.value,
        },
        query: {
          q: encodeQueryObject(qObj),
        },
      });
      break;
    }
    default:
      break;
  }
};

onMounted(() => {
  window.addEventListener("scroll", blurMention);
});

onBeforeUnmount(() => {
  if (badgeId.value == "") return;
  const element = document.getElementById(badgeId.value);
  if (element) {
    element.parentElement.removeChild(document.getElementById(badgeId.value));
  }
});

onUnmounted(() => {
  window.removeEventListener("scroll", blurMention);
});
</script>

<style scoped lang="scss">
.m-entity-link {
  display: inline-block;
  position: relative;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  cursor: pointer;

  label {
    user-select: none;
  }

  * {
    cursor: pointer;
  }

  &__underline {
    display: inline;
  }

  &:hover {
    .m-entity-link {
      position: relative;

      &__underline {
        display: inline;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;

        color: inherit;
        background: linear-gradient(
          to bottom,
          transparent 40%,
          color($highlight) 40%,
          color($highlight) 80%,
          transparent 80%
        );
      }

      &__image {
        display: block;
      }
    }
  }
}
</style>
