<template>
  <div
    :id="`m_article_${snakeCase(article.id)}`"
    ref="articleRef"
    tabindex="0"
    role="button"
    :data-tooltip-content="articleTitle.title"
    data-tooltip-position="dynamic"
    class="m-article"
    :class="[
      `m-article--${setLayout}`,
      {
        'm-article--saved': setLayout == 'list' && article.isSaved,
        'm-article--read': article.isRead,
        'm-article--unread': !article.isRead,
      },
    ]"
    :style="articleStyle"
    @mouseenter="hover.expanded = true"
    @mouseleave="hover.expanded = false"
    @click="goToArticle(article)"
    @keydown.enter="goToArticle(article)"
  >
    <div class="m-article__container">
      <div class="m-article__wrapper">
        <div
          v-if="setLayout != 'list' && setLayout != 'preview'"
          class="m-article__icon"
        >
          <m-icon
            :icon="legixDocumentTypeIcon"
            variant="tertiary"
            size="xsmall"
            status="active"
            hover="highlight"
            :class="`m-article-legix__${legixDocumentType}`"
          />
        </div>
        <div class="m-article__content">
          <div class="m-article__heading">
            <h4
              class="type--small mt-1 mb-1 m-article-legix__designation"
              :dir="langDirection(selectedLang)"
              :style="`color: ${legixDocumentTypeColor};`"
            >
              {{ designation ?? t("article.main_title_untitled") }}
            </h4>
            <component
              :id="`m_article_title_${article.id}`"
              :is="'h5'"
              class="h5 type--small"
              :dir="langDirection(selectedLang)"
            >
              {{ title }}
            </component>
          </div>
          <div class="m-article__bottom">
            <m-duplicates
              v-if="setLayout == 'list'"
              :article="article"
              :lang="selectedLang"
            />
            <div v-if="setLayout == 'list'" class="mx-2 m-article__tags">
              <p
                v-if="articleTitle.lang != articleLang"
                :id="`m_article_${snakeCase(article.id)}_tag`"
                :data-tooltip-content="translatedTooltip"
                data-tooltip-position="dynamic"
                class="h5 type--xsmall m-article__tag"
              >
                {{ articleLang }}
              </p>
              <m-icon
                v-if="getTooltip('visibility')"
                :icon="visibilityIcon"
                variant="white"
                status="active"
                size="small"
                :tooltip="getTooltip('visibility')"
                class="m-article__tag"
              />
            </div>
            <div
              v-if="setLayout == 'details' || setLayout == 'preview'"
              class="m-article__feed"
            >
              <h5 class="type--default type--xsmall mb-1">{{ institution }}</h5>
            </div>
            <m-article-search
              v-if="snippet && setLayout == 'details' && articleSnippet"
              class="pt-2 pb-1"
              :search="articleSnippet"
              :language="articleLang"
            />
            <span
              v-if="author"
              class="m-article__author type--default type--500 type--xsmall"
            >
              {{ author }}
            </span>
            <h6
              class="m-article__date"
              :class="setLayout == 'list' ? 'mr-3 type--small' : 'type--xsmall'"
            >
              {{ date }}
              <template v-if="publicationDate && date != publicationDate"
                >(Publ. a {{ publicationDate }})</template
              >
            </h6>
            <p
              v-if="
                setLayout == 'preview' &&
                article.metadata?.compliance?.visibility == 'open'
              "
              class="type--reading"
            >
              <m-text-formatter
                v-if="text"
                :text="text"
                :format="textFormat"
                :langDirection="langDirection(selectedLang)"
                :canEditEntities="false"
                :showTranslation="false"
                :article="article"
                :selectedLang="selectedLang"
              />
            </p>
            <div class="m-article__actions" @click.stop>
              <m-duplicates
                v-if="setLayout != 'list'"
                :article="article"
                :lang="selectedLang"
              />
              <m-icon
                v-if="actions.includes('read')"
                :id="readIconId"
                :icon="readStatus"
                variant="secondary"
                size="small"
                :tooltip="getTooltip('read')!"
                :title="getTooltip('read')!.content"
                :disabled="disableActions"
                @mouseenter="toggleReadIcon"
                @mouseleave="toggleReadIcon"
                @click="changeReadStatus"
              />
              <m-icon
                v-if="actions.includes('save')"
                :id="saveIconId"
                :icon="saveStatus"
                variant="secondary"
                size="small"
                class="m-article__icon-save"
                :class="[`m-article__icon-save--${saveStatus}`]"
                :tooltip="getTooltip('save')!"
                :title="getTooltip('save')!.content"
                :disabled="disableActions"
                @mouseenter="toggleSaveIcon"
                @mouseleave="toggleSaveIcon"
                @click="toggleSave"
              />
              <template v-if="actions.includes('smart-tagging')">
                <m-icon
                  :icon="tagStatus === true ? 'thumbs-up-filled' : 'thumbs-up'"
                  variant="secondary"
                  size="small"
                  class="m-article__icon-thumbs-up"
                  tooltip="Upvote"
                  title="Upvote title"
                  :disabled="disableActions"
                  @click="emit('upvote')"
                />
                <m-icon
                  :icon="
                    tagStatus === false ? 'thumbs-down-filled' : 'thumbs-down'
                  "
                  variant="secondary"
                  size="small"
                  class="m-article__icon-thumbs-down"
                  tooltip="Downvote"
                  title="Downvote title"
                  :disabled="disableActions"
                  @click="emit('downvote')"
                />
              </template>
              <template v-if="actions.includes('print')">
                <m-icon
                  icon="print"
                  variant="secondary"
                  size="small"
                  tooltip="Print this document"
                  :disabled="true"
                />
              </template>
              <template v-if="actions.includes('share')">
                <m-icon
                  icon="share"
                  variant="secondary"
                  size="small"
                  ref="shareIconRef"
                  tooltip="Obtain a sharable URL"
                  :disabled="disableActions"
                  @click="shareDocument"
                />
                <m-dropdown
                  id="m_share"
                  :visible="openShareDropdown"
                  size="small"
                  :parent="shareIconRef?.ref"
                  :search="false"
                  :floating="true"
                  :custom="true"
                >
                  <template v-slot:custom>
                    <div class="pa-1">
                      <m-input
                        :label="t('article.shareLabel')"
                        :modelValue="shareLink"
                        :can-clear="false"
                        :auto-select="true"
                        size="xsmall"
                        class="m-dropdown__input"
                      />
                    </div>
                  </template>
                </m-dropdown>
              </template>
              <template v-if="actions.includes('source')">
                <m-icon
                  icon="open-new-tab"
                  variant="secondary"
                  size="small"
                  tooltip="Open source in a new tab"
                  :disabled="disableActions"
                  @click="openFeed"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- 
      // Commented because it's not stable
      <div
        v-if="
          hasTeaser &&
          articleVisibility != 'restricted' &&
          teaser &&
          hover.expanded
        "
        class="mt-2"
      >
        <p
          class="m-article__teaser h6 type--xsmall"
          :class="{ 'type--empty': !teaser }"
        >
          {{ teaser }}
        </p>
        <p class="h6 m-article__estimated-time">
          {{ readingTime }}
        </p>
      </div> -->
      <m-article-search
        v-if="snippet && setLayout != 'details' && articleSnippet"
        :search="articleSnippet"
        :language="selectedLang"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, unref, watch, computed, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useApi } from "@api/api";
import useDateFormat from "@hooks/useDateFormat";
import useTranslator from "@hooks/useTranslator";
import useArticle from "@hooks/useArticle";
import MIcon from "@components/MIcon.vue";
import MTextFormatter from "@components/MTextFormatter.vue";
import MDuplicates from "@components/MDuplicates.vue";
import MArticleSearch from "@components/MArticleSearch.vue";
import { transformFormatting } from "@utils/textFormatterUtils";
import { isEmpty, snakeCase } from "lodash-es";
import { eventBus } from "@root/utils/eventBus";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useNavigationStore } from "@root/store/modules/navigation";
import type { MonitioAPI } from "@root/types.api.local";
import { useAlertsStore } from "@root/store/modules/alerts";
import MDropdown from "@root/components/MDropdown.vue";
import useDropdown from "@root/hooks/useDropdown";
import MInput from "@root/components/MInput.vue";
import {
  decodeQueryObject,
  encodeQueryObject,
  navigateWithQueryObject,
  useFiltersStore,
} from "@root/store/modules/filters";

interface Props {
  readonly article: MonitioAPI.SearchDocumentDTO;
  readonly actions?: Array<
    "read" | "save" | "smart-tagging" | "print" | "share" | "source"
  >;
  readonly disableActions?: boolean;
  readonly hasTeaser?: boolean;
  readonly canOpen?: boolean;
  readonly snippet?: boolean;
  readonly layout?: "details" | "list" | "preview";
  readonly tagStatus?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  actions: () => ["read", "save"],
  disableActions: false,
  hasTeaser: false,
  canOpen: true,
  snippet: false,
  layout: "details",
  tagStatus: false,
});

const emit = defineEmits([
  "change-read-status",
  "save-article",
  "unsave-article",
  "update:article",
  "upvote",
  "downvote",
]);

const { t } = useI18n();
const workspacesStore = useWorkspacesStore();
const navigationStore = useNavigationStore();
const filtersStore = useFiltersStore();
const alertsStore = useAlertsStore();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const { api } = useApi();
const router = useRouter();
const route = useRoute();
const { push, currentRoute, resolve } = useRouter();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);

const { dateFormat } = useDateFormat();
const { localizeCountryCode, localizeLanguageCode } = useTranslator();

const viewId = computed<string>(
  () => (route.params.viewId as string) ?? workspaceConfig.value.baseViewId
);

const articleRef = ref(null);
const articleStyle = ref("");
const feedLinkRef = ref(null);

const {
  openDropdown: openShareDropdown,
  keepDropdownOpen: keepShareDropdownOpen,
  closeDropdown: closeShareDropdown,
} = useDropdown();
const shareIconRef = ref();
const shareLink = ref();
const shareDocument = async () => {
  openShareDropdown.value = true;
  try {
    const { data: shareCode } = await api.documents!.getDocumentShareCode(
      viewId.value,
      props.article!.id!,
      route.fullPath
    );

    const url = router.resolve({
      name: "redirect",
      params: {
        code: shareCode,
      },
    });
    const { protocol, host } = window.location;
    shareLink.value = `${protocol}//${host}/${url.href}`;
    navigator.clipboard.writeText(shareLink.value);
  } catch (error) {
    alertsStore.add({
      id: "share-error",
      type: "toast",
      variant: "error",
      message: t("general.alerts.toast.linkFailedToCopy"),
    });
  }
};

const { saveArticle, unSaveArticle } = useArticle(viewId);
const hover = ref({
  save: false,
  read: false,
  expanded: false,
});

const setLayout = computed(() => {
  if (props.layout) return props.layout;
  else if (
    [
      "home",
      "summary",
      "report",
      "tiles",
      "tiles-cluster",
      "headlines",
      "headlines-cluster",
      "manageFilters",
      "createManageFilters",
      "article",
    ].includes(currentRoute.value.name)
  ) {
    return "card";
  } else return viewsStore.layout;
});

watch(
  () => setLayout.value,
  (val, oldVal) => {
    if (val != oldVal) {
      articleStyle.value = "";

      nextTick(() => {
        if (setLayout.value != "card") {
          // articleStyle.value = `height: ${
          //   articleRef.value.getBoundingClientRect().height
          // }px`;
        }
      });
    }
  },
  { immediate: true }
);

const userLang = computed(() => userStore.i18nLanguage.split("-")[0]);
const articleLang = computed(() => props.article.documentLang ?? "en");
const articleTranscriptionLang = computed(
  () => props.article?.transcriptionLang ?? "en"
);

const selectedLang = computed(() => {
  if (
    userStore.account?.translateFrom?.includes(
      articleLang.value?.split("-")[0]
    ) &&
    props.article?.translations?.[userLang.value]?.title
  ) {
    return userLang.value;
  } else return articleLang.value;
});

const title = computed<string>(() => {
  return props.article.metadata?.legixMetadata?.title ?? "";
});
const designation = computed<string>(() => {
  return props.article.translations![props.article.language!].title!;
});
const institution = computed(
  () =>
    props.article.metadata?.sourceItemTypedScopes?.["legiX$Institutions"]?.[0]
);
const publicationDate = computed<string>(() => {
  if (!props.article?.metadata?.legixMetadata?.publicationDate) return "";
  return dateFormat(
    props.article?.metadata?.legixMetadata?.publicationDate,
    "dynamic"
  );
});
const date = computed(() => {
  return dateFormat(props.article?.date, "dynamic");
});

const legixDocumentType = computed(
  () =>
    (props.article.metadata?.legixMetadata
      ?.documentType as MonitioAPI.LegixType) ??
    ("normal " as MonitioAPI.LegixType.Normal)
);

const legixDocumentTypeIcon = computed(() => {
  switch (legixDocumentType.value) {
    case "legalCode" as MonitioAPI.LegixType.LegalCode:
      return "libraries";
    case "library" as MonitioAPI.LegixType.Library:
      return "book-open";
    case "video" as MonitioAPI.LegixType.Video:
      return "video";
    case "normal" as MonitioAPI.LegixType.Normal:
    default:
      return "reports";
  }
});
const legixDocumentTypeColor = computed(() => {
  switch (legixDocumentType.value) {
    case "legalCode" as MonitioAPI.LegixType.LegalCode:
      return "rgba(168, 76, 27, 1)";
    case "library" as MonitioAPI.LegixType.Library:
      return "rgba(136, 47, 94, 1)";
    case "video" as MonitioAPI.LegixType.Video:
      return "rgba(101, 50, 154, 1)";
    case "normal" as MonitioAPI.LegixType.Normal:
    default:
      return "rgba(42, 85, 163, 1)";
  }
});

/** @description stores an array with all avaiable languages in wich there is main text */
const availableLangs = computed(() =>
  Object.keys(props.article?.translations ?? {}).filter(
    (key) => props.article?.translations?.[key].mainText
  )
);

const openFeed = () => {
  if (feed.value.url) {
    let cleanLink = feed.value.url?.split("?amp;utm_")[0];
    const hasParams = cleanLink.split("?")[1];

    if (hasParams) cleanLink = `${cleanLink}&`;
    else cleanLink = `${cleanLink}?`;
    window.open(`${cleanLink}utm_source=monitio`, "_blank");
  }
};

const articleTitle = computed(() => {
  const translations = props.article?.translations ?? {};
  if (translations[selectedLang.value]?.title) {
    return {
      title: translations[selectedLang.value]?.title,
      lang: selectedLang.value,
    };
  } else {
    return {
      title: t("article.main_title_untitled"),
      lang: articleLang.value,
    };
  }
});

watch(
  () => articleTitle.value,
  (oldTitle, newTitle) => {
    if (oldTitle.title != newTitle.title) {
      navigationStore.setArticleTitle(newTitle.title);
    }
  }
);

const articleVisibility = computed(
  () => props.article?.metadata?.compliance?.visibility
);

const articleBilling = computed(() => {
  const billingType = props.article?.metadata?.compliance?.billingType;
  return billingType?.split("_")[0];
});

const visibilityIcon = computed(() => {
  if (articleVisibility.value) return articleVisibility.value;
  else if (articleBilling.value) return articleBilling.value;
  else return "";
});

const translatedTooltip = computed(() => {
  if (articleTitle.value.lang != articleLang.value) {
    const language =
      localizeLanguageCode(articleLang.value).charAt(0).toUpperCase() +
      localizeLanguageCode(articleLang.value).slice(1);
    return t("article.main_translated", { language });
  } else return null;
});

const articleURL = computed(
  () =>
    props.article?.metadata?.cannonicalUrl ??
    props.article?.metadata?.sourceItemUrlList?.[0]
);

const articleSnippet = computed(() => {
  if (viewsStore.showSnippet) return props.article?.metadata?.highlight;
  else return null;
});

const feed = computed(() => {
  return {
    name: props.article?.metadata?.sourceItemFeeds?.[0]?.name,
    url: articleURL.value,
  };
});

const feedInfo = computed(() => {
  const platform = props.article.metadata?.sourceItemTypedScopes?.platform?.[0];

  const feedCountry = localizeCountryCode(
    props.article.metadata?.sourceItemTypedScopes?.feedCountry?.[0] ?? ""
  );

  const district = localizeCountryCode(
    props.article.metadata?.sourceItemTypedScopes?.district?.[0] ?? ""
  );

  const info = [];

  if (feedCountry) info.push(feedCountry);
  if (district) info.push(district);
  if (platform) info.push(platform);

  return info;
});

const author = computed(
  () =>
    props.article.metadata?.sourceItemTypedScopes?.[
      "/customMetadata/sourceItemTypedScopes/$Author"
    ]?.[0]
);

const isHoveringSaveIcon = ref(false);
const saveStatus = computed(() => {
  if (props.article?.isSaved) {
    if (isHoveringSaveIcon.value) return "saved";
    return "saved-filled";
  } else {
    if (isHoveringSaveIcon.value) return "saved-filled";
    return "saved";
  }
});

const readStatus = computed(() => {
  if (props.article?.isRead) return "read";
  else return "unread";
});

eventBus.on("read-all", () => {
  emit("change-read-status", true);
});

const toggleSaveIcon = (evt) => {
  if (evt.type == "mouseleave") {
    isHoveringSaveIcon.value = false;
  } else {
    isHoveringSaveIcon.value = true;
  }
};

const saveIconId = computed(() => {
  const id = snakeCase(props.article?.id);
  const status = snakeCase(saveStatus.value);
  return `${id}_${status}`;
});

const toggleReadIcon = (evt) => {
  const read = props.article?.isRead;
  if (
    (read && readStatus.value == "read") ||
    (!read && readStatus.value == "unread")
  ) {
    if (evt.type == "mouseleave") return;
  }
  if (readStatus.value == "read") readStatus.value = "unread";
  else readStatus.value = "read";
};

const readIconId = computed(() => {
  const id = snakeCase(props.article?.id);
  const status = snakeCase(readStatus.value);
  return `${id}_${status}`;
});

const changeReadStatus = () => {
  emit("change-read-status", !props.article?.isRead);
};

const goToArticle = (article: MonitioAPI.SearchDocumentDTO) => {
  if (props.canOpen) {
    const qObj = { ...filtersStore.queryObject };
    if (!isEmpty(qObj?.contextualFilters)) {
      delete qObj.contextualFilters;
    }
    if (route.name == "createManageFilters") {
      /** If we are previwing articles in the vreate view page we need to open the article in a new tab */
      const routeData = resolve({
        name: "article",
        params: {
          ...route.params,
          viewId: viewId.value ?? unref(workspaceConfig.value.baseViewId),
          articleId: article.id,
        },
        query: { ...route.query, q: encodeQueryObject(qObj) },
      });
      window.open(routeData.href, "_blank");
    } else {
      navigateWithQueryObject(route, router, qObj, "article", {
        viewId: viewId.value ?? unref(workspaceConfig.value.baseViewId),
        articleId: article.id,
      });
    }
  }
};

const toggleSave = () => {
  if (props.article.isSaved) {
    emit("unsave-article");
  } else {
    emit("save-article");
  }
};

const langDirection = (lang: string) => {
  return (
    workspacesStore.languages.find((x) => x.isoCode == lang.split("-")[0])
      ?.direction ?? "lrt"
  );
};

const getTooltip = (type: "save" | "read" | "visibility") => {
  const tooltip = { content: "", position: "dynamic" };

  if (type == "visibility") {
    if (articleBilling.value == "premium") {
      tooltip.content = t(`article.main_${articleBilling.value}`);
    } else if (
      articleVisibility.value == ("expired" as MonitioAPI.Visibility.Expired) ||
      articleVisibility.value ==
        ("restricted" as MonitioAPI.Visibility.Restricted) ||
      articleVisibility.value ==
        ("embargoed" as MonitioAPI.Visibility.Embargoed)
    ) {
      tooltip.content = t(`article.main_${articleVisibility.value}`);
    } else return null;
  } else {
    if (props.disableActions) {
      tooltip.content = t("article.sharedViewError");
    } else if (type == "save") {
      tooltip.content = t(`article.side_button_${saveStatus.value}`);
    } else if (type == "read") {
      tooltip.content = t(
        `article.side_button_${props.article?.isRead ? "unread" : "read"}`
      );
    }
  }

  return tooltip;
};

const articleTranslations = computed(() => props.article.translations);
/** @description Has the text corresponsing to the selected language, or english, or if not avaiable the native article language*/
const text = computed(() => {
  const transcriptionText =
    articleTranslations.value?.[articleTranscriptionLang.value]
      ?.transcription ??
    articleTranslations.value?.[selectedLang.value]?.transcription;

  if (transcriptionText) return transcriptionText;

  const selectedLangText = articleTranslations.value?.[selectedLang.value];
  if (selectedLangText?.mainText) return selectedLangText.mainText;
  return "";
});

const format = computed(() => {
  if (!articleTranslations.value?.[selectedLang.value]?.formatting?.mainText)
    return [];
  return transformFormatting(
    articleTranslations.value[selectedLang.value].formatting?.mainText,
    props.article
  );
});

const textFormat = computed(() => {
  return [...(format.value ?? [])];
});
</script>

<style scoped lang="scss">
.m-article-page__main {
  width: $column-6;

  .m-article-page--legix__description {
    display: flex;
    align-items: center;
    color: color($pri);

    .m-icon {
      margin-right: 10px;
    }
  }
}

.m-article__feed {
  h5 {
    @include opacity-inactive;
  }
}

.m-article__author {
  color: color($text-medium);
}

:deep(.m-text-formatter-legal *) {
  .m-article-page {
    &__title {
      margin-bottom: 8px;
    }

    &__subtitle {
      margin-bottom: 4px;
    }

    &__date {
      font-size: 0.75rem;
      line-height: 1rem;
    }

    &__institution {
      display: block;
      margin-bottom: 6px;
      padding: 0px 6px;
      border-radius: 5px;
      font-size: 0.875rem;
      line-height: 1.375rem;
      background-color: rgb(237, 237, 237);
      color: color($text-light);
    }
  }
}

.remissions {
  &__header {
    display: inline-block;
    padding: 4px 10px;
    margin-bottom: 10px;
    background-color: color($sec-100);
    border-radius: 4px;
  }

  &__not-found {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.m-article-legix__designation {
  display: flex;
  align-items: center;
  color: color($pri);

  .m-icon {
    margin-right: 10px;
  }
}

.m-article-legix {
  &__legalCode {
    fill: rgba(168, 76, 27, 1);
    &.m-icon--highlight {
      background: rgba(168, 76, 27, 0.1);
    }
  }
  &__library {
    fill: rgba(136, 47, 94, 1);
    &.m-icon--highlight {
      background: rgba(136, 47, 94, 0.1);
    }
  }
  &__video {
    fill: rgba(101, 50, 154, 1);
    &.m-icon--highlight {
      background: rgba(101, 50, 154, 0.1);
    }
  }
  &__normal {
    fill: rgba(42, 85, 163, 1);
    &.m-icon--highlight {
      background: rgba(42, 85, 163, 0.1);
    }
  }
}
</style>
