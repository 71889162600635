/* eslint-disable @typescript-eslint/no-non-null-assertion */
// User state
import { useApi } from "@api/api";
import structuredClone from "@utils/structuredClone";
import storage from "@utils/tokenStorage";
import { useSignalR } from "@root/hubs/main";
import { defineStore } from "pinia";
import type { MonitioAPI } from "@root/types.api.local";
import type { AxiosResponse } from "axios";
import { camelCase } from "lodash-es";

interface UserStoreState {
  moduleHasInit: boolean;
  details: MonitioAPI.WorkspaceUserDTO;
  config: MonitioAPI.WorkspaceUserConfig;
  account: MonitioAPI.AccountConfig;
  organization: MonitioAPI.OrganizationDTO;
  smartTagTypes: MonitioAPI.SmartTagTypeDTO[];
  menu: string[];
  i18nLanguage: string;
  ongoingTour: { name: string | undefined; currentStep: number | undefined };
  pinnedPane: boolean;
  theme: string;
}

export const useUserStore = defineStore("user", {
  state: (): UserStoreState => ({
    moduleHasInit: false,
    details: {},
    config: {},
    account: {},
    organization: {},
    smartTagTypes: [],
    menu: [],
    i18nLanguage: localStorage.getItem("i18nLanguage") || "en",
    ongoingTour: { name: undefined, currentStep: undefined },
    pinnedPane: true,
    theme: "light",
  }),

  getters: {
    timeZone: (state) =>
      state.account.timeZone ??
      (localStorage.getItem("timezone") ||
        Intl.DateTimeFormat().resolvedOptions().timeZone),
    getToursTaken: (state) => state.account.toursTaken,
    hasAccessToFeature: (state) => {
      return (featureName: keyof MonitioAPI.FeatureAccess) => {
        const camelCased = camelCase(
          featureName
        ) as keyof MonitioAPI.FeatureAccess;
        const firstLetterSmall = (featureName.charAt(0).toLowerCase() +
          featureName.substring(1)) as keyof MonitioAPI.FeatureAccess;

        return (
          state.details?.featureAccess?.[featureName] ??
          state.details?.featureAccess?.[camelCased] ??
          state.details?.featureAccess?.[firstLetterSmall] ??
          false
        );
      };
    },
  },

  actions: {
    async init() {
      const signalR = useSignalR();
      if (this.moduleHasInit) {
        console.debug("User module already initialized");
        return;
      }

      await this.loadUser();
      this.pinnedPane = this.account.pinnedPane ?? false;

      console.debug(`Observing workspaceUser: {${this.details.id}}`);
      await signalR.observe(this.details.id);

      console.debug(`Observing account: {${this.details.accountId}}`);
      await signalR.observe(this.details.accountId);

      for (const team of this.details.teams ?? []) {
        console.debug(`Observing team: {${team.id}}`);
        await signalR.observe(team.id);
      }

      this.moduleHasInit = true;
    },
    async shutdown() {
      const { api } = useApi();
      const signalR = useSignalR();
      await api.auth!.logout(storage.refreshToken!);
      storage.reset();
      this.menu = [];
      this.details = this.config = this.account = {};
      localStorage.removeItem("i18nLanguage");
      localStorage.removeItem("timezone");

      this.details.teams?.forEach((team) => {
        signalR.release(team.id);
      });

      this.moduleHasInit = false;
    },
    async startTour(name: string | undefined) {
      this.ongoingTour.name = name;
    },
    async retakeTour(name: string) {
      this.ongoingTour.name = name;
    },
    async updateStepTour(currentStep: number | undefined) {
      this.ongoingTour.currentStep = currentStep;
    },
    async completeTour(name: string) {
      try {
        this.account.toursTaken ??= [];
        if (name) {
          this.account.toursTaken.push(name);
        } else if (this.ongoingTour?.name) {
          this.account.toursTaken.push(this.ongoingTour.name);
        }
        this.updateStepTour(undefined);
        this.startTour(undefined);
        this.updateUserDetails(this.config, this.account);
      } catch (error) {
        console.debug(error);
      }
    },
    async clearTours() {
      this.account.toursTaken = [];
    },
    checkCompletedTour(name: string) {
      return this.account.toursTaken?.find((f) => f == name) ?? false;
    },
    async loadUser() {
      const { api } = useApi();

      try {
        const { data } = (await api.user!.getDetails()) as AxiosResponse<
          MonitioAPI.WorkspaceUserDTO | undefined
        >;
        await this.getUserSmartTags();
        if (!data) throw new Error();
        this.details = data;
        this.config = this.details.workspaceUserPersonalization ?? {};
        this.account = this.details.accountPersonalization ?? {};
        this.organization = this.details.organization ?? {};

        this.i18nLanguage = this.account?.i18nLanguage ?? "en";
        localStorage.setItem("i18nLanguage", this.i18nLanguage);
        localStorage.setItem(
          "timezone",
          this.account?.timeZone ||
            Intl.DateTimeFormat().resolvedOptions().timeZone
        );
      } catch (e) {
        console.debug(e);
      }
    },
    async getUserSmartTags() {
      const { api } = useApi();
      try {
        const { data } = (await api.tags!.getUserTags()) as AxiosResponse<
          MonitioAPI.SmartTagTypeDTO[]
        >;
        this.smartTagTypes = data ?? [];
      } catch (e) {
        console.debug(e);
      }
    },
    async updateUserDetails(
      workspaceUserPersonalization: MonitioAPI.WorkspaceUserConfig | null,
      accountPersonalization: MonitioAPI.AccountConfig | null
    ) {
      const { api } = useApi();

      try {
        await api.user!.changeConfigFields(
          workspaceUserPersonalization,
          accountPersonalization
        );
        if (workspaceUserPersonalization)
          this.details.workspaceUserPersonalization =
            workspaceUserPersonalization;
        if (accountPersonalization)
          this.details.accountPersonalization = accountPersonalization;

        this.config = this.details.workspaceUserPersonalization ?? {};
        this.account = this.details.accountPersonalization ?? {};
      } catch (error) {
        console.debug(error);
      }
    },
    async updateUserPropertyTypeConfig(
      propertyTypeConfig: {
        [key: string]: { [key: string]: MonitioAPI.SearchPropertyTypeDTO[] };
      },
      localOnly = false
    ) {
      const { api } = useApi();
      const clone = structuredClone(propertyTypeConfig);

      if (!localOnly) {
        for (const setting in clone) {
          for (const workspaceId in clone[setting]) {
            clone[setting][workspaceId] = clone[setting][workspaceId]?.filter(
              (x: MonitioAPI.SearchPropertyTypeDTO) =>
                x.sourceLevel == "workspaceUser"
            );
          }
        }

        console.log(propertyTypeConfig, clone);

        api.user!.updateUserPropertyTypeConfig(clone);
      }
      this.config = {
        ...this.config,
        propertyTypeSettings: propertyTypeConfig,
      };
    },
    async updateUserSpecificPropertyTypeConfig(
      section: keyof MonitioAPI.PropertyTypeSettings,
      workspaceId: string,
      filters: MonitioAPI.SearchPropertyTypeDTO[]
    ) {
      this.config.propertyTypeSettings ??= {};
      this.config.propertyTypeSettings[section] ??= {};
      this.config.propertyTypeSettings[section][workspaceId] = filters;
    },
    async updateAccount(
      accountUserDetails: MonitioAPI.AccountUserDetails,
      pictureUrl: string,
      email: string
    ) {
      const { api } = useApi();

      try {
        await api.user!.updateAccountInfo(
          accountUserDetails,
          pictureUrl,
          email
        );
        if (accountUserDetails)
          this.details.accountDetails = accountUserDetails;
        if (pictureUrl && pictureUrl != "")
          this.details.accountPersonalization!.pictureUrl = pictureUrl;
        if (email && email != "") this.details.email = email;
      } catch (error) {
        console.debug(error);
      }
    },
    /** @description Request the team to be created and then updates the store accordingly, if successful */
    async createTeam(
      userIds: string[],
      teamDescription: string,
      teamName: string
    ) {
      const { api } = useApi();

      try {
        await api.user!.createTeam(teamName, teamDescription, userIds);
        await this.loadUser();
      } catch (error) {
        console.debug("Unable to create team:", error);
      }
    },

    async addToTeam(userId: string, teamId: string) {
      const { api } = useApi();
      try {
        const { data } = (await api.user!.addUserToTeam(
          teamId,
          userId
        )) as AxiosResponse<MonitioAPI.WorkspaceUserDTO | undefined>;
        if (!data) throw new Error();
        const teamIdx = this.details?.teams?.findIndex((x) => x.id == teamId);
        if (!teamIdx) throw new Error();
        this.details.teams![teamIdx]!.members!.push(data);
      } catch (error) {
        console.debug("Unable to add user from team:", error);
      }
    },

    async removeFromTeam(userId: string, teamId: string) {
      const { api } = useApi();

      try {
        await api.user!.removeUserFromTeam(teamId, userId);
        if (!this.details.teams) return;
        const teamIdx = this.details.teams.findIndex((x) => x.id == teamId);
        this.details.teams[teamIdx].members = this.details.teams[
          teamIdx
        ].members!.filter((x) => x.id != userId);
      } catch (error) {
        console.debug("Unable to remove user from team:", error);
      }
    },

    async archiveTeam(teamId: string) {
      const { api } = useApi();

      try {
        await api.user!.archiveTeam(teamId);
        if (this.details.teams)
          this.details.teams = this.details.teams.filter((x) => x.id != teamId);
      } catch (error) {
        console.debug("Unable to archive team:", error);
      }
    },
    /** @description Grabs the current state of the folders and sends to backend */
    async updateFoldersOrder() {
      const { api } = useApi();
      const folderIdsAndOrder = Object.fromEntries(
        this.details.folders?.map((x) => [
          x.id,
          { order: x.metadata.order, parentFolderId: x.parentFolderId },
        ]) ?? []
      );

      api.user!.updateWorkspaceFoldersOrder(folderIdsAndOrder);
    },
    async createWorkspaceUserFolder(
      data: MonitioAPI.CreateWorkspaceUserFolderDTO
    ) {
      const { api } = useApi();
      const result = await api.user?.createWorkspaceUserFolder(data);
      if (result?.status != 200) throw new Error();
      this.details.folders?.push(result.data);
    },
    //#region Language related stuff
    async addTranslateFromLanguage(langCodes: string[]) {
      langCodes.forEach((langCode) => {
        if (this.account.translateFrom?.includes(langCode)) return;
        if (!this.account.translateFrom) this.account.translateFrom = [];
        const newLangs = [...this.account.translateFrom, langCode];

        this.details ??= {};
        this.details.accountPersonalization ??= {};
        this.details.accountPersonalization.translateFrom = newLangs;
      });

      if (this.details.accountPersonalization)
        await this.updateUserDetails(null, this.details.accountPersonalization);
    },
    async removeTranslateFromLanguage(langCodes: string[]) {
      langCodes.forEach((langCode) => {
        if (!this.account?.translateFrom?.includes(langCode)) return;
        if (!this.account.translateFrom) this.account.translateFrom = [];
        const newLangs = this.account.translateFrom.filter(
          (x) => x != langCode
        );

        this.details ??= {};
        this.details.accountPersonalization ??= {};
        this.details.accountPersonalization.translateFrom = newLangs;
      });

      if (this.details.accountPersonalization)
        await this.updateUserDetails(null, this.details.accountPersonalization);
    },
    async updateUserLanguage(langCode: string) {
      this.details ??= {};
      this.details.accountPersonalization ??= {};
      this.details.accountPersonalization.i18nLanguage = langCode;
      await this.updateUserDetails(null, this.details.accountPersonalization);
      localStorage.setItem("i18nLanguage", langCode);
      this.i18nLanguage = langCode;
    },
    async updateUserTimeZone(timezone: string) {
      this.details ??= {};
      this.details.accountPersonalization ??= {};
      this.details.accountPersonalization.timeZone = timezone;
      await this.updateUserDetails(null, this.details.accountPersonalization);
      localStorage.setItem(
        "timezone",
        this.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      localStorage.setItem(
        "timezone",
        timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
      );
    },
    async setPinnedPane(value: boolean) {
      const accountConfig = structuredClone(this.account);
      try {
        accountConfig.pinnedPane = value;
        await this.updateUserDetails(null, accountConfig);
        this.pinnedPane = value;
      } catch (error) {
        console.debug("unable o save changes. Details follow:", error);
      }
    },
    async setTheme(value: "light" | "dark") {
      const accountConfig = structuredClone(this.account);
      try {
        accountConfig.theme = value;
        await this.updateUserDetails(null, accountConfig);
        this.theme = value;
      } catch (error) {
        console.debug("unable o save changes. Details follow:", error);
      }
    },
    //#endregion
  },
});
