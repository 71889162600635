<template>
  <a
    v-if="article.metadata?.sourceItemIdAtOrigin !== entity.referredId"
    class="type--reading"
    :id="id"
    data-tooltip-template="articlePreview"
    data-tooltip-clickable="true"
    :data-tooltip-content="JSON.stringify(tooltipContent)"
    @mouseenter="searchArticles"
    @click="openArticle"
    ><slot></slot
  ></a>
  <template v-else>
    <slot></slot>
  </template>
</template>

<script setup lang="ts">
import { useApi } from "@root/api/api";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useTooltip } from "@root/tooltips/useTooltip";
import type { MonitioAPI } from "@root/types.api.local";
import { transformFormatting } from "@root/utils/textFormatterUtils";
import { delay, isEmpty } from "lodash-es";
import { computed, ref, unref } from "vue";
import { useRoute, useRouter } from "vue-router";

interface Props {
  id: number;
  originalStartPos: number;
  originalEndPos: number;
  class?: string;
  dir: string | undefined;
  entity: MonitioAPI.EntityDTO;
  article: MonitioAPI.SearchDocumentDTO;
}

const props = defineProps<Props>();
const workspacesStore = useWorkspacesStore();
const { updateTooltipContent } = useTooltip();
const route = useRoute();
const { push, resolve } = useRouter();
const { api } = useApi();

const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const workspaceId = ref(workspaceConfig.value.id);
const viewId = computed(
  () => (route.params.viewId as string) ?? workspaceConfig.value.baseViewId
);

const id = computed(() => `legix-link-${props.id}`);
const previewingDocId = ref<string>();
const tooltipContent = ref({
  previewMessage: "Pré-visualização do artigo",
  title: "Aguarde para pré-visualizar o artigo",
  text: "",
});

const searchArticles = async () => {
  if (!api.search) return;
  if (tooltipContent.value.text !== "") return;

  let result: MonitioAPI.SearchDocumentDTO[] | null | undefined = undefined;
  if (props.entity.referredId) {
    try {
      const remote = await api.search?.search(
        viewId.value,
        {
          timeFrame: "all" as MonitioAPI.TimeFrame,
          isRelative: true,
        },
        0,
        1,
        [
          {
            facets: [
              {
                value: "sourceItemIdAtOrigin",
                query: [
                  {
                    value: props.entity.referredId,
                    negated: false,
                    propertyType: "sourceItemIdAtOrigin",
                  },
                ],
                mode: "singleType" as MonitioAPI.FilterGroupMode.SingleType,
              },
            ],
          },
        ],
        "relevance",
        "all",
        true,
        true
      );

      result = remote.data.result?.documents;
    } catch (error) {
      console.warn(
        "Failed to retrieve document with sourceItemIdAtOrigin: ",
        props.entity.referredId,
        "\n Falling back to standard searchs"
      );
    }
  }

  if (result === undefined || result?.length === 0) {
    try {
      const remote = await api.search?.search(
        viewId.value,
        {
          timeFrame: "all" as MonitioAPI.TimeFrame,
          isRelative: true,
        },
        0,
        1,
        [
          {
            facets: [
              {
                value: "customQuery",
                query: [
                  {
                    value: props.entity.value ?? props.entity.label,
                    negated: false,
                    propertyType: "customQuery",
                  },
                ],
                mode: "singleType" as MonitioAPI.FilterGroupMode.SingleType,
              },
            ],
          },
        ],
        "relevance",
        "all",
        true,
        true
      );

      result = remote.data.result?.documents;
    } catch (error) {
      console.warn("Something went wrong with the standart search");
    }
  }

  if (result === undefined || result?.length === 0) {
    tooltipContent.value.title = "Não foram encontrados documentos";
    delay(() => updateTooltipContent(document.getElementById(id.value)!), 300);
  } else {
    if (
      result?.length === 1 ||
      result!
        .at(0)
        ?.translations?.[result!.at(0)!.documentLang!].title?.toLowerCase()
        .trim() === props.entity.value?.toLowerCase().trim()
    ) {
      const doc = result!.at(0)!;
      const title = doc.translations?.[doc.documentLang!].title ?? "";

      const legixSections = doc.metadata?.legixMetadata?.legixFormatting;

      const summaryStart = legixSections?.["summary"].at(0)?.start ?? 0;
      const summaryEnd = legixSections?.["summary"].at(0)?.end ?? 200;

      const text =
        doc.translations?.[doc.documentLang!].mainText?.slice(
          summaryStart,
          summaryEnd
        ) ?? "";
      const format = transformFormatting(
        structuredClone(
          doc.translations?.[doc.documentLang!]?.formatting?.mainText ?? {}
        ),
        props.article
      )!;

      tooltipContent.value.previewMessage = "Clique para abrir o documento.";
      tooltipContent.value.title = title;
      tooltipContent.value.text = formatText(text, format) as string;
      previewingDocId.value = doc.id;
    } else {
      tooltipContent.value.title =
        "Clique em qualquer um dos documentos para abrir";
      tooltipContent.value.text = "Foram encontradas várias opções:\n";
      tooltipContent.value.text += result
        ?.map((doc) => {
          const title = doc.translations?.[doc.documentLang!].title ?? "";
          const url = resolve({
            name: "article",
            params: {
              viewId: viewId.value ?? unref(workspaceConfig.value.baseViewId),
              articleId: doc.id,
            },
            query: route.query,
          });
          return `<p><a href="${url.href}" target="_blank">${title}</a></p>`;
        })
        .join("");
    }
  }

  delay(() => updateTooltipContent(document.getElementById(id.value)!), 300);
};

const openArticle = () => {
  if (!previewingDocId.value) return;
  push({
    name: "article",
    params: {
      viewId: viewId.value ?? unref(workspaceConfig.value.baseViewId),
      articleId: previewingDocId.value,
    },
    query: route.query,
  });
};

/** @description takes in the text and the format and transforms them into html */
const formatText = (
  text: string,
  format: ReturnType<typeof transformFormatting>
) => {
  if (!text || !format) return "";
  const formats = format;
  formats.sort((a, b) => b.ord - a.ord);

  const list: (string | any)[] = [text];
  let ids = 0;
  formats.forEach((el) => {
    if (!el) return;
    el.id = ids++;
    if (el.start < el.end) {
      const startidx = splitList(list, el.start);
      list.splice(startidx, 0, {
        type: "start",
        tag: el.tag,
        id: el.id,
        el: el,
      });
      const endidx = splitList(list, el.end);
      list.splice(endidx, 0, { type: "end", tag: el.tag, el: el });
    } else {
      const idx = splitList(list, el.start);
      list.splice(
        idx,
        0,
        { type: "start", tag: el.tag, id: el.id, el: el },
        { type: "end", tag: el.tag, el: el }
      );
    }
  });

  let result = '<div class="m-tooltip--reading">';

  result += list
    .map((f) => {
      switch (f.type) {
        case "start": {
          let result = "";
          if (f.tag == "img") result += `<${f.tag} src="${f.el?.src}"`;
          else result += `<${f.tag} class="${f.el?.class}"`;
          if (f.el?.att && !isEmpty(f.el.att)) {
            result += ` ${Object.entries(f.el?.att).map(
              ([key, value]) => `${key}="${value}"`
            )}`;
          }
          result += ">";
          return result;
        }
        case "end":
          if (f.tag == "br") return ""; // If br tag we dont close them, or they will get duplicated in html
          return `</${f.tag}>`;
        default:
          return f;
      }
    })
    .join("");
  result += "</div>";
  return result;
};

const splitList = (list: unknown[], position: number) => {
  if (position == 0) return 0;
  for (let i = 0; i < list.length; i++) {
    const el = list[i];
    if (typeof el !== "string") continue;
    if (position <= el.length) {
      const before = el.substring(0, position);
      const after = el.substring(position);

      if (before.length != 0) {
        list[i] = after;
        list.splice(i, 0, before);
        return i + 1;
      } else return i;
    }
    position -= el.length;
  }
  return list.length;
};
</script>
