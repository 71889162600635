<template>
  <grid-layout
    v-if="layout.length && delayGridRender"
    class="m-dashboard-children"
    :layout="layout"
    :col-num="columnN"
    :row-height="rowHeight"
    :margin="margin"
    :is-draggable="false"
    :is-resizable="type == 'editor'"
    :vertical-compact="false"
    :prevent-collision="true"
    :use-css-transforms="true"
  >
    <grid-item
      v-for="(el, i) in layout"
      :key="i"
      class="m-dashboard__el"
      :class="{ 'm-dashboard__el--selected': selectedElementId == el.i }"
      :x="el.x"
      :y="el.y"
      :w="el.w"
      :h="el.h"
      :i="el.i"
      :static="type != 'editor'"
      :is-draggable="type == 'editor'"
      @moved="movedEvent"
      @resized="resizedEvent"
    >
      <m-dashboard-element
        :type="type"
        :element="el.element"
        :width="el.w"
        :height="el.h"
        :viewId="viewId"
        :date-restriction-override="dateRestrictionOverride"
        :parentFilters="advancedFilters ?? { facets: [] }"
        :row-height="rowHeight"
        :isChildren="true"
        @select="selectElement"
        @remove="removeElement"
        @chart-data-changed="(data, id) => emit('chart-data-changed', data, id)"
        @click="() => emit('click')"
      />
    </grid-item>
  </grid-layout>
</template>

<script setup>
import { ref, computed, reactive, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useWindowSize } from "@vueuse/core";
import { useRoute } from "vue-router";

import MDashboardElement from "@components/dashboard/MDashboardElement.vue";
import useDashboards from "@hooks/useDashboards";

const props = defineProps({
  elements: { type: Array },
  viewId: String,
  advancedFilters: { type: Object, required: false },
  type: { type: String, default: "preview" },
  dateRestrictionOverride: { type: Object, default: null },
  parentLayout: { type: Object, required: false },
  rowHeight: { type: Number, default: 228 },
  selectedElementId: { type: String },
});

const emit = defineEmits([
  "select-element",
  "add-element",
  "remove-element",
  "resized-element",
  "chart-data-changed",
  "update-layout",
]);

const { t } = useI18n();
const { width: windowWidth } = useWindowSize();
const route = useRoute();
const margin = ref([16, 16]); // $spacing-4
const { getAddElementCoords } = useDashboards();

const layout = ref(
  props.elements.map((m) => ({
    ...m.layout,
    element: m,
  }))
);

watch(
  () => layout.value,
  (layout) => {
    emit("update-layout", layout);
  },
  { deep: true }
);

watch(
  () => props.elements,
  (val, oldVal) => {
    if (val.length === oldVal.length) {
      // No changes in layout. So update only the elements inside
      val.forEach((element) => {
        const idx = layout.value.findIndex((x) => x.element?.id == element.id);
        layout.value[idx].element = element;
      });
      return;
    }
    if (val.length > oldVal.length) {
      const { x, y } = getAddElementCoords(layout.value, columnN.value);
      //Insert an item
      const item = val.find(
        (x) => !oldVal.map((m) => m.layout.i).includes(x.layout.i)
      );
      layout.value.push({
        ...item.layout,
        x,
        y,
        element: item,
      });
      //recalculateLayoutPositions(layout.value, columnN.value);
    } else {
      //Remove an item
      const item = oldVal.find(
        (x) => !val.map((m) => m.layout.i).includes(x.layout.i)
      );
      const currentIdx = layout.value.findIndex((x) => item.layout.i == x.i);
      layout.value.splice(currentIdx, 1);
      //recalculateLayoutPositions(layout.value, columnN.value);
    }
  }
);

const columnN = computed(() => {
  if (props.parentLayout) return props.parentLayout.w;
  else return 1;
});

const delayGridRender = ref(false);
const addElement = reactive({});

onMounted(() => {
  setTimeout(() => {
    // We need to delay rendering of the grid, else the GridLayout throws an error
    delayGridRender.value = true;
  }, 100);
});

watch(
  () => columnN.value,
  () => {
    /*  recalculateLayoutPositions(layout.value, columnN.value); */
  }
);

const movedEvent = () => {
  // layout.value = reorderLayout(layout.value);
  // recalculateLayoutPositions(layout.value, columnN.value, addElement);
};

const resizedEvent = (i, newH, newW, newHPx, newWPx) => {
  /*  recalculateLayoutPositions(layout.value, columnN.value); */
  emit("resized-element", i, newH, newW);
};

const selectElement = (val) => {
  emit("select-element", val);
};

const removeElement = (val) => {
  emit("remove-element", val);
};
</script>
