<template>
  <!-- $spacing-1   -->
  <skeleton-loader
    type="box"
    :corner="4"
    :border="false"
    class="skeleton-loader"
    :class="`skeleton-loader--${layout}`"
  >
    <div class="skeleton-loader__container">
      <div class="skeleton-loader__wrapper">
        <div class="skeleton-loader__image">
          <skeleton-loader
            v-if="layout != 'list'"
            type="image"
            :corner="4"
            class="m-image"
          />
        </div>
        <div class="skeleton-loader__content">
          <div class="skeleton-loader__heading">
            <m-type-skeleton
              :type="layout == 'details' ? 'h2' : 'h4'"
              width="100%"
              :lines="layout == 'list' ? 1 : titleLines"
              :size="layout == 'details' ? 'xsmall' : 'default'"
              class="skeleton-loader__title"
            />
            <m-type-skeleton
              v-if="layout == 'card'"
              type="h6"
              size="small"
              width="100"
              :lines="1"
              class="skeleton-loader__feed"
            />
          </div>
          <div class="skeleton-loader__bottom">
            <m-type-skeleton
              v-if="layout != 'card'"
              type="h6"
              size="xsmall"
              width="100"
              :lines="1"
              class="skeleton-loader__feed"
            />
            <m-type-skeleton
              v-if="layout != 'list'"
              type="h6"
              size="xsmall"
              width="80"
              :lines="1"
              class="skeleton-loader__date"
              :class="{ 'mr-3': layout == 'list' }"
            />
          </div>
        </div>
      </div>
    </div>
  </skeleton-loader>
</template>
<script setup>
import { computed } from "vue";

import { useRoute, useRouter } from "vue-router";
import { useWindowSize } from "@vueuse/core";
import MTypeSkeleton from "@components/skeletons/MTypeSkeleton.vue";
import SkeletonLoader from "@priberam/skeleton-loader";
import { useViewsStore } from "@root/store/modules/views";

const props = defineProps({
  titleLines: { type: Number, default: 2 },
});

const viewsStore = useViewsStore();
const { currentRoute } = useRouter();
const { width: windowWidth } = useWindowSize();

const layout = computed(() => {
  return viewsStore.layout;
});

// set width according desktop size
const setWidth = computed(() => {
  let finalWidth = "";

  if (windowWidth.value < 1280) {
    finalWidth = "calc((100% - 24px) / 3)";
  } else {
    finalWidth = "calc((100% - 48px) / 4)";
  }

  return finalWidth;
});
</script>

<style scoped>
:deep(:root) {
  --line-height: 5rem;
}
</style>
