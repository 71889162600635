import type { MonitioAPI } from "@root/types.api.local";
import type { AxiosInstance } from "axios";

export default (axios: AxiosInstance) => ({
  list() {
    return axios.get("/v1/view");
  },
  getShareList() {
    return axios.get("/v1/view/share-list");
  },
  getSubscriptionDetails(viewId: string) {
    return axios.get(`/v1/view/${viewId}/subscription`);
  },
  unsubscribeNewsletter(viewId: string) {
    return axios.put(`/v1/view/${viewId}/unsubscribe`);
  },
  unsubscribeNewsletterViaToken(token: string) {
    return axios.put(`/v1/view/unsubscribe/${token}`);
  },
  subscribeNewsletter(viewId: string, frequency = "daily") {
    return axios.put(`/v1/view/${viewId}/subscribe?frequency=${frequency}`);
  },
  shareWith(viewId: string, withObjs: MonitioAPI.ShareTargets) {
    return axios.put(`/v1/view/share/${viewId}`, withObjs);
  },
  unshareWith(viewId: string, withObjs: MonitioAPI.ShareTargets) {
    return axios.put(`/v1/view/unshare/${viewId}`, withObjs);
  },
  updateUserViewsOrderAndFolder(data: MonitioAPI.UpdateViewsOrderFolderDTO) {
    return axios.put<void>(`/v1/view/update-user-views-order-folder`, data);
  },
  saveAs(view: MonitioAPI.ViewDTO, parentViewId: string) {
    return axios.post("/v1/view", {
      ...view,
      parentViewId,
    });
  },
  delete(viewId: string) {
    return axios.delete(`/v1/view/${viewId}`);
  },
  createDraftView(parentViewId: string | null = null) {
    return axios.get<string>(`/v1/view/create-draft-view`, {
      params: {
        parentViewId,
      },
    });
  },
  uploadPicture(viewId: string, picture: string | ArrayBuffer | null) {
    return axios.post(`/v1/view/picture/${viewId}`, picture, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
  },
});
