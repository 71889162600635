/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useApi } from "@api/api";
import { useSignalR } from "@root/hubs/main";
import { defineStore } from "pinia";
import type { MonitioAPI } from "@root/types.api.local";
import type { AxiosResponse } from "axios";
//@ts-ignore
import base_image from "@assets/images/monitio_thumbnail.png";
import { useUserStore } from "./user";
import { useDashboardsStore } from "./dashboards";
import { useReportsStore } from "./reports";
import { useAlertsStore } from "./alerts";
import guid from "@root/utils/guid";
import { eventBus } from "@root/utils/eventBus";

interface ViewStoreState {
  moduleHasInit: boolean;
  userViews: MonitioAPI.ViewDTO[];
  sharedViews: MonitioAPI.ViewDTO[];
  draftViews: MonitioAPI.ViewDTO[];
  lastOpenedViewsIds: string[];
  nav: "expanded" | "collapsed";
  show: "all" | "read" | "unread";
  sortBy: "relevance" | "date";
  sort: "name" | "latest" | "oldest";
  aggregator: "iptc" | "language" | "districtProvince";
  showSnippet: boolean;
  layout: "card" | "details" | "list";
  filters: MonitioAPI.FrontendFiltersGroup[];
  filterCount: number;
  expandedFilterCount: number;
  readingAllArticles: boolean;
  allArticlesRead: boolean;
  entityBadge: {
    id: string;
    image: string;
    type: string;
    dir: "ltr" | "rtl";
    label: string;
  } | null;
}

//#region Some utils functions specific of this store
/** @description Function that ensure the objects have all the properties populated, from the moment you use this function you can be sure that there's no undefineds or nulls */
const ensureShareTargets = (view: MonitioAPI.ViewDTO) => {
  const keys: (keyof MonitioAPI.Targets)[] = ["workspaceUser", "team"];

  for (const key of keys) {
    view.sharedWith ??= {};
    view.sharedWith.targets ??= {};
    view.sharedWith.targets[key] ??= [] as MonitioAPI.ShareTarget[];
  }
};

//#endregion

export const useViewsStore = defineStore("views", {
  state: (): ViewStoreState => ({
    moduleHasInit: false,
    userViews: [] as MonitioAPI.ViewDTO[],
    sharedViews: [] as MonitioAPI.ViewDTO[],
    lastOpenedViewsIds: (localStorage.getItem("lastOpenedViewsIds")
      ? JSON.parse(localStorage.getItem("lastOpenedViewsIds") ?? "")
      : []) as string[],
    draftViews: (localStorage.getItem("draftViews")
      ? JSON.parse(localStorage.getItem("draftViews") ?? "")
      : []) as MonitioAPI.ViewDTO[],
    nav: (localStorage.getItem("viewNav") || "expanded") as
      | "expanded"
      | "collapsed",
    show: (localStorage.getItem("show") || "all") as "all" | "read" | "unread",
    sortBy: (localStorage.getItem("sortBy") || "date") as "relevance" | "date",
    sort: (localStorage.getItem("sort") || "name") as
      | "name"
      | "latest"
      | "oldest",
    aggregator:
      (localStorage.getItem("aggregator") as "iptc" | "language") || "iptc",
    showSnippet:
      (localStorage.getItem("showSnippet") as boolean | null) || true,
    layout: (localStorage.getItem("layout") as "card" | "list") || "card",
    filters: [],
    filterCount: 6,
    expandedFilterCount: 20,
    readingAllArticles: false,
    allArticlesRead: false,
    entityBadge: null,
  }),

  getters: {
    getViewById() {
      return (viewId: string): MonitioAPI.ViewDTO | undefined =>
        this.draftViews
          .concat(this.userViews)
          .concat(this.sharedViews)
          .find((x) => x.id == viewId);
    },
    getDraftViewById() {
      return (viewId: string): MonitioAPI.ViewDTO | undefined =>
        this.draftViews.find((x) => x.id == viewId);
    },
    getAllViews: (state) => state.userViews.concat(state.sharedViews),
    lastOpenedViews: (state) => {
      const allViews = state.userViews.concat(state.sharedViews);
      return [...state.lastOpenedViewsIds]
        .reverse()
        .map((_) => allViews.find((x) => x.id == _));
    },
  },

  actions: {
    async init() {
      const signalR = useSignalR();

      if (this.moduleHasInit) {
        console.warn("Views module already initialized");
        return;
      }
      await this.loadViews();

      this.userViews.concat(this.sharedViews).forEach((x) => {
        console.debug(`Observing view: {${x.id}}`);
        signalR.observe(x.id);
      });

      this.moduleHasInit = true;
    },
    async shutdown() {
      localStorage.removeItem("currentViewId");
      await this.resetViews();
      this.moduleHasInit = false;
    },
    async addView(view: MonitioAPI.ViewDTO) {
      this.userViews.push(view);
    },
    /** @description Can either save or update a view */
    async saveView(view: MonitioAPI.ViewDTO, baseViewId: string) {
      const { api } = useApi();
      view.isDraft = false;
      try {
        const response = await api.views!.saveAs(view, baseViewId);
        if (response.status != 200) throw new Error("Something went wrong"); //Alert the user that somehting went wrong
        view = response.data;
        if (!view?.id) throw new Error("unable to save view");

        if (this.draftViews.some((x) => x.id == view.id)) {
          this.addView({ ...view });
          if (view.id) this.removeDraftViewById(view.id, false);
        } else {
          if (!this.userViews.some((x) => x.id == view.id))
            this.addView({ ...view });
          this.updateView({ ...view });
        }

        //#region the reports and dashboards that should be visible to all the views in the store
        const dashboardsStore = useDashboardsStore();
        dashboardsStore.list.forEach((dashboard) => {
          if (dashboard.showInAllViews) {
            dashboard.showedIn ??= [];
            dashboard.showedIn.push(view.id!);
          }
        });
        const reportsStore = useReportsStore();
        await reportsStore.getRemoteReports();
        //#endregion

        return view.id;
      } catch (error) {
        console.debug(error);
      }
    },
    async addDraftView(view: MonitioAPI.ViewDTO, baseViewId: string) {
      const { api } = useApi();
      view.isDraft = true;
      try {
        const response = await api.views!.saveAs(view, baseViewId);
        if (response.status != 200) throw new Error("Something went wrong"); //Alert the user that somehting went wrong
        view = response.data;
        this.draftViews.push(view);
      } catch (error) {
        console.log(error);
      }
    },
    async updateDraftView(view: MonitioAPI.ViewDTO, baseViewId: string) {
      const { api } = useApi();
      view.isDraft = true;
      try {
        const response = await api.views!.saveAs(view, baseViewId);

        if (response.status != 200) throw new Error("Something went wrong"); //Alert the user that somehting went wrong
        //view = response.data;

        const idx = this.draftViews.findIndex((x) => x.id == view.id);
        if (idx < 0) return;
        this.draftViews[idx] = view;
      } catch (error) {
        console.debug(error);
      }
    },
    async removeDraftViewById(id: string, remoteDelete = true) {
      const { api } = useApi();
      try {
        if (remoteDelete) await api.views!.delete(id);
        const idx = this.draftViews.findIndex((x) => x.id == id);
        if (idx < 0) return;
        this.draftViews.splice(idx, 1);
      } catch (error) {
        console.debug(error);
      }
    },
    async removeViewById(viewId: string) {
      const { api } = useApi();
      try {
        await api.views!.delete(viewId);
        const idx = this.userViews.findIndex((x) => x.id == viewId);
        if (idx < 0) return;
        this.userViews.splice(idx, 1);
      } catch (error) {
        console.debug(error);
      }
    },
    async removeSharedViewById(id: string) {
      const idx = this.sharedViews.findIndex((x) => x.id == id);
      if (idx < 0) return;
      this.sharedViews.splice(idx, 1);
      localStorage.setItem("draftViews", JSON.stringify(this.sharedViews));
    },
    /** @description Updates de view state */
    async updateView(view: MonitioAPI.ViewDTO) {
      const views = {
        userViews: this.userViews,
        draftViews: this.draftViews,
        sharedViews: this.sharedViews,
      };

      for (const key of Object.keys(views)) {
        const typedKey = key as "userViews" | "draftViews" | "sharedViews";
        const idx = this[typedKey]?.findIndex((x) => x.id == view.id);
        if (idx >= 0) {
          this[typedKey][idx] = view;
          localStorage.setItem(key, JSON.stringify(this[typedKey]));
          return;
        }
      }
    },
    /** @description Updates de view state */
    async updateViewProperty(
      prop: keyof MonitioAPI.ViewDTO,
      view: MonitioAPI.ViewDTO
    ) {
      const views = {
        userViews: this.userViews,
        draftViews: this.draftViews,
        sharedViews: this.sharedViews,
      };

      for (const key of Object.keys(views)) {
        const typedKey = key as keyof typeof views;
        const idx = this[typedKey]?.findIndex((x) => x.id == view.id);
        if (idx >= 0) {
          //@ts-ignore
          this[typedKey][idx][prop] = view[prop];
          localStorage.setItem(typedKey, JSON.stringify(this[typedKey]));
          return;
        }
      }
    },
    async setNav(option: "expanded" | "collapsed") {
      this.nav = option;
      localStorage.setItem("viewNav", option);
    },
    async setShow(option: "all" | "read" | "unread") {
      this.show = option;
      localStorage.setItem("show", option);
    },
    async setSortBy(option: "relevance" | "date") {
      this.sortBy = option;
      localStorage.setItem("sortBy", option);
    },
    async setSort(option: "name" | "latest" | "oldest") {
      this.sort = option;
      localStorage.setItem("sort", option);
    },
    async setAggregator(option: "iptc" | "language" | "districtProvince") {
      this.aggregator = option;
      localStorage.setItem("aggregator", option);
    },
    async setShowSnippet(option: boolean) {
      this.showSnippet = option;
      localStorage.setItem("showSnippet", `${option}`);
    },
    async setLayout(option: "card" | "details" | "list") {
      this.layout = option;
      localStorage.setItem("layout", option);
    },
    async changeFilters(_options: string[]) {
      /*       const idx = this.filters.findIndex((f) => f.value == options.value);
      if (idx != -1) {
        this.filters.splice(idx, 1);
      } else this.filters.push(options); */
    },
    async setFilters(filters: MonitioAPI.FrontendFiltersGroup[]) {
      if (filters) this.filters = filters;
      else this.filters = [];
    },
    async loadViews() {
      const { api } = useApi();
      try {
        const { data } = (await api.views!.list()) as AxiosResponse<
          MonitioAPI.GetViewsResponseDTO | undefined
        >;
        if (!data) throw new Error();
        this.userViews =
          data.userViews
            ?.filter((x) => !x.isDraft)
            ?.sort((a, b) => a.name?.localeCompare(b?.name ?? "") ?? 0)
            .map((x) => {
              //Insert a default image here in the frontend in case the view doesnt have an image
              x.details ??= {};
              if (!x.details.image) x.details.image = base_image;
              return x;
            }) || [];
        this.draftViews =
          data.userViews
            ?.filter((x) => x.isDraft)
            ?.sort((a, b) => a.name?.localeCompare(b?.name ?? "") ?? 0)
            .map((x) => {
              //Insert a default image here in the frontend in case the view doesnt have an image
              x.details ??= {};
              if (!x.details.image) x.details.image = base_image;
              return x;
            }) || [];
        this.sharedViews =
          data.sharedViews
            ?.sort((a, b) => a.name?.localeCompare(b?.name ?? "") ?? 0)
            .map((x) => {
              //Insert a default image here in the frontend in case the view doesnt have an image
              x.details ??= {};
              if (!x.details.image) x.details.image = base_image;
              return { ...x, isShared: true };
            }) || [];
        if (this.userViews.length || this.sharedViews.length) {
          let view = null;
          const storedviewId = localStorage.getItem("currentViewId");
          if (storedviewId)
            view = [...this.userViews, ...this.sharedViews].find(
              (f) => f.id === storedviewId
            );

          if (!view)
            view = this.userViews.length
              ? this.userViews[0]
              : this.sharedViews[0];
          localStorage.setItem("currentViewId", view.id || "");
        }
      } catch (e) {
        this.userViews = [];
        this.sharedViews = [];
        throw e;
      }
    },
    async incrementViewArticleRead(viewId: string) {
      const view =
        this.userViews.find((f) => f.id === viewId) ||
        this.sharedViews?.find((f) => f.id === viewId);
      //if (view) view.count++;
      //api.search.updateDocumentReadStatus(mode, viewid, docid, index, params);
    },
    async decrementViewArticleRead(viewId: string) {
      const view =
        this.userViews.find((f) => f.id === viewId) ||
        this.sharedViews?.find((f) => f.id === viewId);
      //if (view) view.count--;
      //api.search.updateDocumentReadStatus(mode, viewId, docid, index, params);
    },
    /** @description Function that requests the backend to read all the documents in the current restriction */
    async markAllAsRead(
      t: any,
      viewId: string,
      dateRestriction: MonitioAPI.DateRestriction,
      filters: MonitioAPI.FrontendFiltersGroup[]
    ) {
      const { api } = useApi();
      const alertsStore = useAlertsStore();

      const view = this.getAllViews?.find((v) => v.id === viewId);
      if (view) {
        try {
          this.readingAllArticles = true;
          await api.search!.requestViewReadAll(
            viewId,
            dateRestriction,
            filters
          );
          alertsStore.add({
            id: guid.NewGuid(),
            type: "toast",
            variant: "success",
            message: t("general.alerts.toast.markAllAsRead"),
          });
          this.readingAllArticles = false;
          this.allArticlesRead = true;
          eventBus.emit("read-all");
        } catch (error) {
          console.debug("Unable to mark all documents as read");
        }
      }
    },
    async setEntityBadge(val: {
      id: string;
      image: string;
      type: string;
      dir: "ltr" | "rtl";
      label: string;
    }) {
      this.entityBadge = val;
    },
    async resetViews() {
      this.userViews = [];
      this.sharedViews = [];
      localStorage.setItem("currentView", "");
    },
    async shareViewWith(viewId: string, withObjs: MonitioAPI.ShareTargets) {
      const { api } = useApi();
      try {
        await api.views!.shareWith(viewId, withObjs);
        const idx = this.userViews.findIndex((x) => x.id == viewId);
        for (const key in withObjs.targets) {
          ensureShareTargets(this.userViews[idx]);

          for (const iterator of withObjs.targets[
            key as keyof MonitioAPI.Targets
          ] ?? []) {
            this.userViews[idx].sharedWith!.targets![
              key as keyof MonitioAPI.Targets
            ]?.push(iterator);
          }
        }
      } catch (error) {
        console.debug(error);
      }
    },
    async unShareViewWith(viewId: string, withObjs: MonitioAPI.ShareTargets) {
      const { api } = useApi();
      const userStore = useUserStore();
      try {
        const { data } = (await api.views!.unshareWith(
          viewId,
          withObjs
        )) as AxiosResponse<MonitioAPI.ShareTargets | undefined>;
        if (!data) return;

        const idx = this.userViews.findIndex((x) => x.id == viewId);
        if (idx >= 0) {
          for (const key in data.targets) {
            ensureShareTargets(this.userViews[idx]);
            this.userViews[idx].sharedWith!.targets![
              key as keyof MonitioAPI.Targets
            ] = this.userViews[idx].sharedWith!.targets![
              key as keyof MonitioAPI.Targets
            ]?.filter(
              (x) =>
                !data.targets?.[key as keyof MonitioAPI.Targets]
                  ?.map((m) => m.id)
                  .includes(x.id)
            );
          }
        }
        const sIdx = this.sharedViews.findIndex((x) => x.id == viewId);
        if (sIdx >= 0) {
          const { accountId, teams, id } = userStore.details;
          for (const key in data.targets) {
            ensureShareTargets(this.sharedViews[idx]);
            this.sharedViews[idx].sharedWith!.targets![
              key as keyof MonitioAPI.Targets
            ] = this.sharedViews[idx].sharedWith!.targets![
              key as keyof MonitioAPI.Targets
            ]?.filter(
              (x) =>
                !data.targets?.[key as keyof MonitioAPI.Targets]
                  ?.map((m) => m.id)
                  .includes(x.id)
            );
          }
          if (
            !this.sharedViews[idx].sharedWith?.targets?.["workspaceUser"]?.some(
              (x) => x.id == id
            ) &&
            !this.sharedViews[idx].sharedWith?.targets?.["team"]?.some((x) =>
              teams?.map((m) => m.id).includes(x.id)
            )
          ) {
            /** This means the current view isnt beeing shared at all with the user in context, so we need to remove it */
            this.sharedViews = this.sharedViews.filter((x) => x.id != viewId);
          }
        }
      } catch (error) {
        console.debug(error);
      }
    },
    async removeUserFromView(viewId: string, workspaceUserId: string) {
      const idx = this.userViews.findIndex((x) => x.id == viewId);
      if (idx >= 0) {
        ensureShareTargets(this.userViews[idx]);
        this.userViews[idx].sharedWith!.targets!["workspaceUser"] =
          this.userViews[idx].sharedWith!.targets!["workspaceUser"]?.filter(
            (x) => x.id != workspaceUserId
          );
      }

      const sIdx = this.sharedViews.findIndex((x) => x.id == viewId);
      if (sIdx >= 0) {
        ensureShareTargets(this.sharedViews[sIdx]);
        this.sharedViews[sIdx].sharedWith!.targets!["workspaceUser"] =
          this.sharedViews[sIdx].sharedWith!.targets!["workspaceUser"]?.filter(
            (x) => x.id != workspaceUserId
          );
      }
    },
    /** @description Grabs the current state of the folders and sends to backend */
    async updateViewsOrderAndFolders() {
      const { api } = useApi();
      const data: MonitioAPI.UpdateViewsOrderFolderDTO = {
        owned: Object.fromEntries(
          this.userViews.map((x) => [
            x.id,
            {
              order: x.order!,
              workspaceUserFolderId: x.workspaceUserFolderId,
            },
          ])
        ),
        shared: Object.fromEntries(
          this.sharedViews.map((x) => [
            x.id,
            {
              order: x.order!,
              workspaceUserFolderId: x.workspaceUserFolderId,
            },
          ])
        ),
      };

      api.views!.updateUserViewsOrderAndFolder(data);
    },
    async updateLastOpenedViews(id: string) {
      if (this.lastOpenedViewsIds.length == 0) {
        this.lastOpenedViewsIds.push(id);
      } else if (!this.lastOpenedViewsIds.includes(id)) {
        this.lastOpenedViewsIds.push(id);
      } else if (this.lastOpenedViewsIds.at(-1) === id) {
        return;
      } else {
        this.lastOpenedViewsIds = this.lastOpenedViewsIds.filter(
          (x) => x !== id
        );
        this.lastOpenedViewsIds.push(id);
      }

      localStorage.setItem(
        "lastOpenedViewsIds",
        JSON.stringify(this.lastOpenedViewsIds)
      );
    },
  },
});
