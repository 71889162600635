import { isEmpty } from "lodash-es";

/**
 * @description should receive a very specificly formtted object and return a usable
 * formmatted array for text-formatter compoenent
 * @param {{[htmlTag: string]: import("@root/types.api.local").MonitioAPI.StartEnd[]}|null|undefined} formating
 * @param {{}} article
 * @returns {{id: number, start:number; end: number;ord: number; tag: string; src?: string, att?: {[key:string]:unkown; cls?: string;id?: string;rowspan?: number;colspan?: number;};}[]}
 */
const transformFormatting = (formating, article) => {
  if (!formating) return [];
  if (isEmpty(formating)) return [];
  return Object.keys(formating).reduce((array, htmlTag) => {
    const grp = formating[htmlTag];
    return array.concat(
      grp.map((element, i) => {
        switch (htmlTag) {
          case "br":
          case "h1":
          case "h2":
          case "h3":
          case "h4":
          case "h5":
          case "h6":
          case "a":
          case "strong":
          case "em":
          case "span":
          case "table":
          case "thead":
          case "tr":
          case "th":
          case "tbody":
          case "tfoot":
          case "td":
            element.tag = htmlTag;
            element.class = html_entities[htmlTag]
              ? html_entities[htmlTag]
              : "";
            break;
          case "p":
            element.tag = htmlTag;
            element.class = html_entities[htmlTag]
              ? `"type--reading ${html_entities[htmlTag]}`
              : "type--reading";
            element.class = "type--reading";
            break;
          case "img":
            // eslint-disable-next-line no-case-declarations
            const imgUrl =
              article.metadata?.documentImages?.[i] ?? element.att?.src;
            if (imgUrl) {
              element.tag = htmlTag;
              element.src = imgUrl;
              element.class = html_entities[htmlTag]
                ? html_entities[htmlTag]
                : "";
              break;
            }
            break;
          default:
            element.tag = "span";
            element.class = html_entities[htmlTag]
              ? html_entities[htmlTag]
              : "";
            break;
        }

        // If the element already comes with a cls (class) property, pass it to the class property,
        // this will result in it beeing rendered with the html tags in the DOM
        element.class ??= "";
        if (element.att?.cls) {
          element.class += ` ${element.att.cls}`;
          delete element.att.cls;
        }

        return element;
      })
    );
  }, []);
};

const html_entities = {
  a: "m-text-formatter__inline",
  abbr: "m-text-formatter__inline",
  acronym: "m-text-formatter__inline",
  address: "m-text-formatter__block",
  applet:
    "m-text-formatter__object m-text-formatter__img m-text-formatter__inline m-text-formatter__param",
  area: "m-text-formatter__block m-text-formatter__empty",
  b: "m-text-formatter__inline",
  base: "m-text-formatter__head m-text-formatter__empty",
  basefont: "m-text-formatter__inline m-text-formatter__empty",
  bdo: "m-text-formatter__inline",
  big: "m-text-formatter__inline",
  blockquote: "m-text-formatter__block",
  body: "m-text-formatter__html m-text-formatter__opt m-text-formatter__omitst",
  br: "m-text-formatter__br",
  button: "m-text-formatter__inline",
  caption: "m-text-formatter__table",
  center: "m-text-formatter__block",
  cite: "m-text-formatter__inline",
  code: "m-text-formatter__inline",
  col: "m-text-formatter__table m-text-formatter__empty",
  colgroup: "m-text-formatter__table m-text-formatter__opt",
  dd: "m-text-formatter__deflist m-text-formatter__opt m-text-formatter__no-indent",
  del: "m-text-formatter__inline m-text-formatter__block m-text-formatter__mixed",
  dfn: "m-text-formatter__inline",
  dir: "m-text-formatter__block m-text-formatter__obsolete",
  div: "m-text-formatter__block",
  dl: "m-text-formatter__block",
  dt: "m-text-formatter__deflist m-text-formatter__opt m-text-formatter__no-indent",
  em: "m-text-formatter__inline",
  fieldset: "m-text-formatter__block",
  font: "m-text-formatter__inline",
  form: "m-text-formatter__block",
  frame: "m-text-formatter__frames m-text-formatter__empty",
  frameset: "m-text-formatter__html m-text-formatter__frames",
  h1: "type--small",
  h2: "type--small",
  h3: "type--small",
  head: "m-text-formatter__html m-text-formatter__opt m-text-formatter__omitst",
  hr: "m-text-formatter__block m-text-formatter__empty",
  html: "m-text-formatter__html m-text-formatter__opt m-text-formatter__omitst",
  i: "m-text-formatter__inline",
  iframe: "m-text-formatter__inline",
  img: "m-text-formatter__inline m-text-formatter__img m-text-formatter__empty",
  input:
    "m-text-formatter__inline m-text-formatter__img m-text-formatter__empty",
  ins: "m-text-formatter__inline m-text-formatter__block m-text-formatter__mixed",
  isindex: "m-text-formatter__block m-text-formatter__empty",
  kbd: "m-text-formatter__inline",
  label: "m-text-formatter__inline",
  legend: "m-text-formatter__inline",
  li: "CM_LIST m-text-formatter__opt m-text-formatter__no-indent",
  link: "m-text-formatter__head m-text-formatter__empty",
  listing: "m-text-formatter__block m-text-formatter__obsolete",
  map: "m-text-formatter__inline",
  menu: "m-text-formatter__block m-text-formatter__obsolete",
  meta: "m-text-formatter__head m-text-formatter__empty",
  noframes: "m-text-formatter__block m-text-formatter__frames",
  noscript:
    "m-text-formatter__block m-text-formatter__inline m-text-formatter__mixed",
  object:
    "m-text-formatter__object m-text-formatter__head m-text-formatter__img m-text-formatter__inline m-text-formatter__param",
  ol: "m-text-formatter__block",
  optgroup: "m-text-formatter__field m-text-formatter__opt",
  option: "m-text-formatter__field m-text-formatter__opt",
  param: "m-text-formatter__inline m-text-formatter__empty",
  plaintext: "m-text-formatter__block m-text-formatter__obsolete",
  pre: "m-text-formatter__block",
  q: "m-text-formatter__inline",
  rb: "m-text-formatter__inline",
  rbc: "m-text-formatter__inline",
  rp: "m-text-formatter__inline",
  rt: "m-text-formatter__inline",
  rtc: "m-text-formatter__inline",
  ruby: "m-text-formatter__inline",
  s: "m-text-formatter__inline",
  samp: "m-text-formatter__inline",
  script:
    "m-text-formatter__head m-text-formatter__mixed m-text-formatter__block m-text-formatter__inline",
  select: "m-text-formatter__inline m-text-formatter__field",
  small: "m-text-formatter__inline",
  span: "m-text-formatter__inline",
  ref: "m-text-formatter__inline",
  strike: "m-text-formatter__inline",
  strong: "m-text-formatter__inline",
  style: "m-text-formatter__head",
  sub: "m-text-formatter__inline",
  sup: "m-text-formatter__inline",
  table: "m-text-formatter__block",
  tbody:
    "m-text-formatter__table m-text-formatter__rowgrp m-text-formatter__opt",
  td: "CM_ROW m-text-formatter__opt m-text-formatter__no-indent",
  textarea: "m-text-formatter__inline m-text-formatter__field",
  tfoot:
    "m-text-formatter__table m-text-formatter__rowgrp m-text-formatter__opt",
  th: "CM_ROW m-text-formatter__opt m-text-formatter__no-indent",
  thead:
    "m-text-formatter__table m-text-formatter__rowgrp m-text-formatter__opt",
  title: "m-text-formatter__head",
  tr: "m-text-formatter__table m-text-formatter__opt",
  tt: "m-text-formatter__inline",
  u: "m-text-formatter__inline",
  ul: "m-text-formatter__block",
  var: "m-text-formatter__inline",
  xmp: "m-text-formatter__block m-text-formatter__obsolete",
  nextid: "m-text-formatter__head m-text-formatter__empty",
  align: "m-text-formatter__block",
  bgsound: "m-text-formatter__head m-text-formatter__empty",
  blink: "m-text-formatter__inline",
  comment: "m-text-formatter__inline",
  embed:
    "m-text-formatter__inline m-text-formatter__img m-text-formatter__empty",
  ilayer: "m-text-formatter__inline",
  keygen: "m-text-formatter__inline m-text-formatter__empty",
  layer: "m-text-formatter__block",
  marquee: "m-text-formatter__inline m-text-formatter__opt",
  multicol: "m-text-formatter__block",
  nobr: "m-text-formatter__inline",
  noembed: "m-text-formatter__inline",
  nolayer:
    "m-text-formatter__block m-text-formatter__inline m-text-formatter__mixed",
  nosave: "m-text-formatter__block",
  server:
    "m-text-formatter__head m-text-formatter__mixed m-text-formatter__block m-text-formatter__inline",
  servlet:
    "m-text-formatter__object m-text-formatter__img m-text-formatter__inline m-text-formatter__param",
  spacer: "m-text-formatter__inline m-text-formatter__empty",
  wbr: "m-text-formatter__inline m-text-formatter__empty",
  article: "m-text-formatter__block",
  aside: "m-text-formatter__block",
  audio: "m-text-formatter__inline",
  canvas: "m-text-formatter__inline",
  command:
    "m-text-formatter__head m-text-formatter__inline m-text-formatter__empty",
  datalist: "m-text-formatter__inline",
  details: "m-text-formatter__block",
  figcaption: "m-text-formatter__block",
  figure: "m-text-formatter__block",
  footer: "m-text-formatter__block",
  header: "m-text-formatter__block",
  hgroup: "m-text-formatter__block",
  mark: "m-text-formatter__inline",
  meter: "m-text-formatter__inline",
  nav: "m-text-formatter__block",
  output: "m-text-formatter__inline",
  progress: "m-text-formatter__inline",
  section: "m-text-formatter__block",
  source: "m-text-formatter__inline m-text-formatter__empty",
  summary: "m-text-formatter__block",
  time: "m-text-formatter__inline",
  track: "m-text-formatter__inline m-text-formatter__empty",
  video: "m-text-formatter__inline",
};

export { html_entities, transformFormatting };
