<template>
  <template v-if="articleType == 'legal'">
    <m-article-legal
      :article="article"
      ref="articleRef"
      :actions="['read', 'save', 'print', 'share', 'source']"
      :disableActions="disableActions"
      :hasTeaser="hasTeaser"
      :canOpen="canOpen"
      :snippet="snippet"
      :layout="layout"
      :tagStatus="tagStatus"
      @change-read-status="(v: unknown) => emit('change-read-status', v)"
      @save-article="() => emit('save-article')"
      @unsave-article="() => emit('unsave-article')"
      @update:article="(v: MonitioAPI.SearchDocumentDTO) => emit('update:article', v)"
      @upvote="() => emit('upvote')"
      @downvote="() => emit('downvote')"
    />
  </template>
  <template v-if="articleType == 'news'">
    <m-article-news
      :article="article"
      ref="articleRef"
      :actions="actions"
      :disableActions="disableActions"
      :hasTeaser="hasTeaser"
      :canOpen="canOpen"
      :snippet="snippet"
      :layout="layout"
      :tagStatus="tagStatus"
      @change-read-status="(v: unknown) => emit('change-read-status', v)"
      @save-article="() => emit('save-article')"
      @unsave-article="() => emit('unsave-article')"
      @update:article="(v: MonitioAPI.SearchDocumentDTO) => emit('update:article', v)"
      @upvote="() => emit('upvote')"
      @downvote="() => emit('downvote')"
    />
  </template>
</template>

<script setup lang="ts">
import MArticleLegal from "./Templates/MArticleLegal.vue";
import MArticleNews from "./Templates/MArticleNews.vue";
import type { MonitioAPI } from "@root/types.api.local";
import { computed, ref } from "vue";

interface Props {
  readonly article: MonitioAPI.SearchDocumentDTO;
  readonly actions?: Array<
    "read" | "save" | "smart-tagging" | "print" | "share" | "source"
  >;
  readonly disableActions?: boolean;
  readonly hasTeaser?: boolean;
  readonly canOpen?: boolean;
  readonly snippet?: boolean;
  readonly layout?: "details" | "list" | "preview";
  readonly tagStatus?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  actions: () => ["read", "save"],
  disableActions: false,
  hasTeaser: false,
  canOpen: true,
  snippet: false,
});

const emit = defineEmits([
  "change-read-status",
  "save-article",
  "unsave-article",
  "update:article",
  "upvote",
  "downvote",
]);

const articleRef = ref();

defineExpose({
  articleRef,
});

/** @description function that will determine what template gets rendered */
const articleType = computed<"news" | "legal" | null>(() => {
  if (!props.article) return null;
  if (props.article.metadata?.legixMetadata) return "legal";
  else return "news";
});
</script>
