<template>
  <div class="m-cluster m-wrapper" :class="`m-wrapper--${viewsStore.nav}`">
    <div v-if="cluster?.data" class="m-cluster__wrapper">
      <div class="m-cluster__header">
        <h2>
          {{ title }}
        </h2>
        <h6>
          <!-- TODO DGF missing feeds -->
          {{
            t("views.cluster.articlesCount", {
              articles: cluster?.totalDocs,
              feeds: undefined,
              languages: cluster?.properties?.reduce((p, c) => {
                if (c.type == "sourceLanguage") p++;
                return p;
              }, 0),
              feeds: feeds.length,
            })
          }}
        </h6>
      </div>

      <m-icon
        icon="view-filter"
        variant="secondary"
        hover="elevate"
        :tooltip="createViewTooltip"
        @click="pinCluster"
      />

      <m-top-cluster
        :cluster="cluster.documents.slice(0, 5)"
        class="mt-5 mb-7"
        @update:cluster="updateCluster"
      />
      <div
        v-if="cluster?.summary?.highlights?.length"
        class="mt-3 mb-7 m-cluster__summary"
      >
        <h2 class="type--xsmall mb-3">
          {{ t("views.cluster.summary") }}
          <m-icon
            id="m_filter_ai_indo"
            icon="info"
            size="xsmall"
            :tooltip="summaryDisclaimer"
          />
        </h2>
        <div
          class="pt-3 pl-3 m-summary"
          :class="`m-summary--${cluster.summary.highlights.length}`"
        >
          <template v-if="cluster.summary">
            <div
              v-for="(highlight, i) in cluster.summary.highlights"
              :key="i"
              :id="`m_story_highlight_${i}`"
              data-tooltip-position="dynamic"
              :data-tooltip-content="highlightTooltip(highlight.info)"
              class="m-summary__quote"
              @click="goToArticle(highlight.info.id)"
            >
              <span class="h4 m-quote__number">{{ i + 1 }}</span>
              <p>
                <span>
                  {{ `"${highlight.highlight}"` }}
                </span>
                <span class="h5 ml-3">
                  {{ highlight.info.feed }}
                  <!-- <span class="mx-1">{{ `(${highlight.info.date})` }}</span> -->
                </span>
              </p>
            </div>
          </template>
        </div>
      </div>
      <div v-else class="mt-3 mb-7 m-cluster__summary">
        <m-type-skeleton type="h2" size="xsmall" width="80" class="mb-3" />
        <div class="pt-3 pl-3 m-summary">
          <div v-for="i in 4" :key="i" class="m-summary__quote">
            <m-type-skeleton type="h4" width="16" class="m-quote__number" />
            <m-type-skeleton type="p" width="100%" />
            <m-type-skeleton type="p" width="100%" />
            <m-type-skeleton type="p" width="66%" />
          </div>
        </div>
      </div>

      <div
        class="mt-3 m-cluster__articles"
        :class="`m-cluster__articles--${viewsStore.nav}`"
      >
        <h2 class="mb-2 type--xsmall">{{ $t("views.cluster.articles") }}</h2>

        <m-filter
          id="cluster_filter"
          :placeholder="$t('components.search.placeholder_searchForAnything')"
          :scoped="false"
          :cluster="cluster"
          :showContextual="false"
        />

        <div class="m-articles__header">
          <h6 v-if="!isLoadingMore" class="my-2 ml-1 type--small">
            {{
              $t("views.cluster.about", currentArticlesSearchDocCount, {
                count: currentArticlesSearchDocCount,
              })
            }}
          </h6>
        </div>
        <div
          ref="htmlElement"
          class="px-0 m-articles__wrapper m-articles__wrapper--card"
        >
          <template v-if="requestingArticles">
            <m-article-skeleton
              v-for="i in articlesSkeleton"
              :key="i"
              :titleLines="layout == 'card' ? 2 : 1"
            />
          </template>
          <template v-else>
            <m-article
              v-for="(article, i) in articles"
              :ref="
                (ref) => {
                  if (ref?.articleRef?.$el)
                    htmlElements.push(ref?.articleRef?.$el);
                }
              "
              :key="i"
              :article="article"
              @update:article="
                (key, val) => {
                  article[key] = val;
                }
              "
              @change-read-status="
                (status) => changeReadStatus(article, status)
              "
            />
          </template>
          <m-loading v-if="isLoadingMore" size="small" class="py-7 my-7" />
        </div>
      </div>
    </div>
    <div v-else-if="!initialRequestCompleted" class="m-cluster__wrapper">
      <div class="m-cluster__header m-type-skeleton__header">
        <m-type-skeleton type="h2" width="100%" />
        <m-type-skeleton type="h2" width="33%" />
        <m-type-skeleton type="h6" size="small" width="250" :lines="1" />
      </div>
      <skeleton-loader
        type="image"
        :corner="4"
        width="28"
        height="28"
        class="m-icon--view-filter"
      />
      <div class="m-top-cluster m-top-cluster--5 m-articles__wrapper">
        <div class="m-top-cluster__large m-top-cluster-skeleton">
          <m-article-skeleton />
        </div>
        <div class="m-top-cluster__small m-top-cluster__small--full-width">
          <m-article-skeleton v-for="i in 4" :key="i" />
        </div>
      </div>
      <div class="mt-3 mb-7 m-cluster__summary">
        <m-type-skeleton type="h2" size="xsmall" width="80" class="mb-3" />
        <div class="m-summary pt-3 pl-3">
          <div v-for="i in 4" :key="i" class="m-summary__quote">
            <m-type-skeleton type="h4" width="16" class="m-quote__number" />
            <m-type-skeleton type="p" width="100%" />
            <m-type-skeleton type="p" width="100%" />
            <m-type-skeleton type="p" width="66%" />
          </div>
        </div>
      </div>
      <m-filter
        id="cluster_filter"
        :placeholder="t('components.search.placeholder_searchForAnything')"
        :scoped="false"
        :showContextual="true"
      />
    </div>
    <div
      v-else-if="!canAccessCluster"
      class="m-cluster__wrapper m-cluster__wrapper--empty"
    >
      <h3 class="type--small type--nowrap type--empty">
        {{ t("views.cluster.noAccessCluster") }}
      </h3>
      <h6 class="type--small type--nowrap type--empty">
        {{ t("views.cluster.noAccessClusterWorkspace") }}
      </h6>
    </div>
    <div v-else class="m-cluster__wrapper m-cluster__wrapper--empty">
      <h3 class="type--small type--nowrap type--empty">
        {{ t("views.cluster.noSearchResults") }}
      </h3>
      <h6 class="type--small type--nowrap type--empty">
        {{ t("views.cluster.tryChanging") }}
      </h6>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  unref,
  computed,
  watch,
  onMounted,
  onUnmounted,
  useTemplateRef,
} from "vue";
import { useI18n } from "vue-i18n";

import { useApi } from "@api/api";
import { useRouter, useRoute } from "vue-router";
import useDateFormat from "@hooks/useDateFormat";
import useArticle from "@hooks/useArticle";
import MFilter from "@components/filter/MFilter.vue";
import MArticle from "@root/components/MArticle/MArticle.vue";
import MTopCluster from "@components/MTopCluster.vue";

import MIcon from "@components/MIcon.vue";
// skeletons's imports
import SkeletonLoader from "@priberam/skeleton-loader";
import MArticleSkeleton from "@components/skeletons/MArticleSkeleton.vue";
import MTypeSkeleton from "@components/skeletons/MTypeSkeleton.vue";
import MLoading from "@components/MLoading.vue";
import useCreateViewModal from "@components/modals/MCreateView/useCreateViewModal";
import { cloneDeep, isEmpty } from "lodash-es";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useNavigationStore } from "@root/store/modules/navigation";
import { useClustersStore } from "@root/store/modules/clusters";
import { useFiltersStore } from "@root/store/modules/filters";
import { useWindowSize } from "@vueuse/core";

const { t } = useI18n();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const filtersStore = useFiltersStore();
const workspacesStore = useWorkspacesStore();
const navigationStore = useNavigationStore();
const route = useRoute();
const { api } = useApi();
const router = useRouter();
const { dateFormat } = useDateFormat();
const { open } = useCreateViewModal();
const clustersStore = useClustersStore();

const { height: windowHeight } = useWindowSize();
const viewId = computed(() => route.params.viewId);
const view = computed(() => viewsStore.getViewById(viewId.value));

const { changeReadStatus } = useArticle(viewId);
const feeds = ref([]);
const clusterId = computed(() => route.params.clusterId);
const layout = computed(() => {
  return viewsStore.layout;
});

const currentArticlesSearchDocCount = ref(null);
const articles = ref([]); //Gets updated with An IntersectionObserver in getArticles()
/** React to user search to update the articles */
watch(
  () => filtersStore.queryObject,
  async (val) => {
    if (!["tiles-cluster", "headlines-cluster"].includes(route.name)) return;

    initialRequestCompleted.value = false;
    isLoadingMore.value = true;
    isReloadingArticles.value = true;
    currentArticlesSearchDocCount.value = null;

    // Update cluster and summary after queryObject changes
    const info = await getClusterData(0, numOfDocsPerRequest.value, val);
    //console.log(info);

    cluster.value.documents = info.documents;
    cluster.value.data = info.data;
    cluster.value.totalDocs = info.totalNumDocuments;
    cluster.value.properties = info.properties;

    feeds.value = info.feeds;

    getClusterSummary(info.documents, userLang.value).then((data) => {
      cluster.value.summary = data;
    });

    //Get new articles after queryObject changes
    const { documents, totalNumDocuments } = await getArticles(
      viewId.value,
      //existingArticles + initialDocsRequested, This damages the view when the person clicks on the filter pane, but it can also make the articles appear again the the article list
      val,
      0,
      numOfDocsPerRequest.value
    );
    articles.value = info.documents;

    currentArticlesSearchDocCount.value = totalNumDocuments;
    articles.value = [...documents];

    isReloadingArticles.value = false;
    isLoadingMore.value = false;
    initialRequestCompleted.value = true;
  }
);

const articlesSkeleton = computed(() => {
  let x = 9;

  x = (windowHeight.value * x) / 720;

  return parseInt(x);
});

const userLang = computed(() => userStore.i18nLanguage.split("-")[0]);
const selectLang = (articleLang) => {
  if (userStore.account?.translateFrom?.includes(articleLang)) {
    return userLang.value;
  } else return articleLang;
};

const title = computed(() => {
  const title = cluster.value.data?.title || t("cluster.main_title_untitled");
  navigationStore.setClusterTitle(title);
  return title;
  // Missing show translation
  // header.value?.engTitle || header.value?.sourceItemTitle || t("article.main_title_untitled");
});

const updateCluster = (id, key, val) => {
  const idx = cluster.value.documents.findIndex((f) => f.id == id);
  cluster.value.documents[idx][key] = val;
};

const canAccessCluster = ref(true);
/** If anyhting is needed to be map/added or removed from the API call do it here */
const cluster = ref(null);
/**
 * @param {import("@root/types").Monitio.URLQueryObject} query
 */
const getClusterData = async (first, last, query) => {
  const filters = query?.filters || [];
  try {
    const response = await api.search.cluster(
      unref(viewId),
      unref(clusterId),
      query?.dateRestriction ?? filtersStore.dateRestriction,
      query?.aggregator ?? filtersStore.aggregator,
      query?.sortBy ?? filtersStore.sortBy,
      first,
      last,
      filters
    );
    if (response.status == 200) {
      clustersStore.addClusterName(
        response.data.cluster.id,
        response.data.cluster.title
      );
      return {
        data: response.data.cluster,
        properties: response.data.properties,
        documents: response.data.result?.documents,
        totalNumDocuments: response.data.result?.numDocuments,
        feeds: response.data.properties?.filter(
          (x) => x.type == "sourceItemFeedNames"
        ),
      };
    }
    if (response.response.status == 404 && response.response.data) {
      //view was not found in the workspace, so check if the user has acess to the workspace of the view warn and switch
      const viewWId = response.response.data;
      const hasAccess = workspacesStore.user
        ?.filter((x) => x.active)
        .find((x) => x.id == viewWId);

      if (hasAccess) {
        router.push({
          name: "switch-workspace",
          params: {
            from: `${route.name}!`,
            viewId: unref(viewId),
            workspaceId: hasAccess.id,
            targetId: unref(clusterId),
          },
        });
      } else throw new Error("No access");
    }
  } catch (error) {
    canAccessCluster.value = false;
    /** NO CONTENT return empty */
    return {
      data: null,
      documents: [],
      totalNumDocuments: 0,
      summary: null,
      feeds: [],
    };
  }
};

const requestingSummary = ref(false);
const getClusterSummary = async (articles, translateTo = "en") => {
  requestingSummary.value = true;
  const { data } = await api.search.summarizeDocuments(translateTo, articles);

  if (!data) return [];

  /** @type {import("@root/types.api.local").MonitioAPI.SummaryHighlightDTO[]} */
  const highlights = data.highlights;

  if (!highlights) return { highlights: [] };
  const originalHighlights = [];
  const translatedHighlights = [];

  highlights.forEach((highlight) => {
    const docInfo = cluster.value.documents?.find(
      (x) => x.id == highlight.sourceDocuments[0]?.id
    );

    const translatedResult = {
      highlight: highlight.translated,
      language: translateTo,
    };
    const originaldResult = {
      highlight: highlight.original,
      language: highlight.originalLanguage,
    };

    if (docInfo) {
      const translatedTitle = docInfo.translations[translateTo]?.title;
      const originalTitle = docInfo.translations[docInfo.documentLang]?.title;
      translatedResult.info = originaldResult.info = {
        title:
          translatedTitle || originalTitle || t("article.main_title_untitled"),
        feed: docInfo.metadata?.sourceItemFeeds?.[0]?.name,
        date: dateFormat(docInfo.date, "static"),
        id: docInfo.id,
      };
    }

    originalHighlights.push(originaldResult);
    if (translatedResult.highlight) translatedHighlights.push(translatedResult);
  });

  requestingSummary.value = false;

  return {
    highlights:
      translatedHighlights.length > 0
        ? translatedHighlights
        : originalHighlights,
  };
};

const summaryDisclaimer = computed(() => {
  return { content: t("disclaimer.ai_summary_cluster"), position: "right" };
});

const loadingArticles = ref(false);
const getArticles = async (
  id,
  query = {},
  first = 0,
  last = 19,
  filterChanged = false
) => {
  return getClusterData(first, last, query);
  // eslint-disable-next-line no-unreachable
  const filters = cloneDeep(query?.filters || []);
  const contextualFilters = cloneDeep(query?.contextualFilters ?? {});

  if (!isEmpty(contextualFilters)) filters.push({ facets: contextualFilters });

  loadingArticles.value = true;

  const response = await api.search.search(
    unref(id),
    query.dateRestriction || filtersStore.dateRestriction,
    first,
    last + 20,
    filters,
    query.sortBy || filtersStore.sortBy,
    "all",
    query?.aggregateDuplicates ?? filtersStore.aggregateDuplicates
  );

  if (response.status != 200) return;
  if (
    response.data.result.numDocuments == null ||
    response.data.result.numDocuments == undefined
  ) {
    //responseHasData.value = false;
  } else {
    /** Get the total number of documents in the backend (sum of all indexes atm) */
    let totalNumOfArticles = 0; //If the request happens more than once in the view we need to reset this
    for (const index in response.data.result.numDocuments) {
      totalNumOfArticles += response.data.result.numDocuments[index];
    }

    if (filtersStore.aggregateDuplicates) {
      //Remove duplicates from the counting, or else will not match
      response.data?.result?.documents.forEach((doc) => {
        if (doc.metadata?.group?.selection_count > 1) {
          totalNumOfArticles -= doc.metadata?.group?.selection_count - 1;
        }
      });
    }

    // eslint-disable-next-line no-unreachable
    loadingArticles.value = false;
    // Set the
    //if (response.data.result.documents.length < last - first + 1)
    //maxArticlesReached.value = true;

    // Check for duplicates
    const articlesN = last - first + 1;

    return {
      documents: response.data?.result?.documents?.slice(0, articlesN) ?? [],
      totalNumDocuments: totalNumOfArticles,
    };
  }
};
/**
 * IntersectionObserver code
 * It decides if the element is showing on screen or not.
 * If it is we do an API call to get the needed data, if not stay hidden and do nothing
 */
const htmlElement = useTemplateRef("htmlElement");
const htmlElements = ref([]);
const requestingArticles = computed(() => articles.value.length == 0);
const isLoadingMore = ref(false);
const isReloadingArticles = ref(false);
const numOfDocsPerRequest = ref(18);
/** @param {IntersectionObserverEntry[]} entries */
const handleIntersection = async (entries) => {
  if (isLoadingMore.value) return;
  if (articles.value.length >= cluster.value.totalDocs) return;

  const info = entries[0];
  if (info.isIntersecting) {
    if (
      currentArticlesSearchDocCount.value &&
      articles.value.length >= currentArticlesSearchDocCount.value
    )
      return;

    // Check if the wrapper div is almost at the bottom
    isLoadingMore.value = true; // to prevent several exact same requests
    const existingArticles = articles.value.length;

    try {
      const { documents, totalNumDocuments } = await getArticles(
        viewId.value,
        //existingArticles + initialDocsRequested, This damages the view when the person clicks on the filter pane, but it can also make the articles appear again the the article list
        filtersStore.queryObject,
        articles.value.length,
        existingArticles + numOfDocsPerRequest.value
      );
      currentArticlesSearchDocCount.value = totalNumDocuments;
      articles.value = [...articles.value, ...documents];
    } catch (error) {
      console.debug(error.message);
    }
    isLoadingMore.value = false;
  }
};
const observer = new IntersectionObserver(handleIntersection);
/** Setup a watcher for each time the array gets modified we
 * remove the old observe and restart de observer in another element */
watch(
  [htmlElement, htmlElements],
  ([parentElement, articlesList]) => {
    /** First disconnect the old observer to prevent memory leaks */
    observer.disconnect();
    if (!parentElement && !articlesList?.length) return;

    if (articlesList.length > 0) {
      /** Then grabbing the new array with more articles setup the observe in on of the new last items */
      const elementsToObserve = articlesList[articlesList.length - 3];
      if (elementsToObserve) observer.observe(elementsToObserve);
    } else {
      observer.observe(parentElement);
    }
  },
  { deep: true }
);

const goToArticle = (id) => {
  router.push({
    name: "article",
    params: {
      viewId: viewId.value ?? workspacesStore.currentWorkspaceConfig,
      articleId: id,
    },
  });
};

const initialRequestCompleted = ref(false);
const initialDocsRequested = 25;
onMounted(async () => {
  const info = await getClusterData(
    0,
    initialDocsRequested - 1,
    filtersStore.queryObject
  );
  cluster.value = {
    data: info.data,
    documents: info.documents,
    totalDocs: info.totalNumDocuments,
    properties: info.properties,
  };

  initialRequestCompleted.value = true;
  feeds.value = info.feeds;

  cluster.value.summary = await getClusterSummary(
    info.documents,
    userLang.value
  );
});

onUnmounted(() => {
  observer.disconnect();
});

/**
 * @description Sends the user to create a new view with the clusterID as a restriction
 */
const pinCluster = () => {
  const filters = view.value?.details?.filters ?? [];
  const newFilter = {
    facets: [
      {
        value: "crossClusterId",
        query: [
          {
            label: cluster.value.data.title,
            thumbnail: cluster.value.documents[0].thumbnail,
            value: cluster.value.data.id,
            propertyType: "crossClusterId",
          },
        ],
      },
    ],
  };
  filters.push(newFilter);

  viewsStore.setFilters(filters);
  open("fromStory");
};

const highlightTooltip = (val) => {
  return t("views.cluster.open", { title: val.title, feed: val.feed });
};

const createViewTooltip = computed(() => {
  return {
    content: t("views.cluster.createView"),
    position: "bottom-left",
  };
});
</script>

<style scoped lang="scss">
.m-cluster {
  width: 100%;

  &__wrapper {
    width: 100%;
    max-height: calc(100vh - 48px);
    padding: $spacing-2 $spacing-10 $spacing-5;
    @include flex(flex-start, stretch, column);
    overflow-y: auto;

    :deep(.m-filter__search) {
      max-width: unset;
    }
  }

  &__header,
  .m-type-skeleton__header {
    padding: $spacing-3 $spacing-0;
  }

  &__summary {
    h2 {
      @include flex(flex-start, flex-start, row);
      gap: $spacing-2;
    }

    .m-icon {
      margin-top: math.div($spacing-1, 2);
    }
  }

  &__container {
    min-height: 100%;

    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .m-icon--view-filter {
    align-self: flex-end;
  }

  .m-summary {
    @include flex(flex-start, flex-start, row);
    flex-wrap: wrap;
    gap: $spacing-5;
    cursor: pointer;

    * {
      cursor: pointer;
    }

    .m-icon--info {
      display: inline-block;
    }

    &__quote {
      width: calc(50% - $spacing-3);
      max-width: $reading-width;
      padding: $spacing-2 $spacing-3;
      border: 1px solid color($sec-200);
      @include round-corners($spacing-1);
      position: relative;

      .m-quote__number {
        padding: $spacing-0 $spacing-1;
        @include round-corners($spacing-1);
        position: absolute;
        top: $spacing-0;
        left: $spacing-0;
        color: color($pri-action-inactive);
        transform: translate(-$spacing-2, -$spacing-3);
        background-color: color($pri-ultra-light);
      }
    }

    &--1 .m-summary__quote {
      width: 100%;
    }
  }

  .m-loading {
    width: 100%;
  }
}

@include mq(desktopS) {
  .m-cluster {
    .m-summary {
      @include flex(flex-start, center, column);
      flex-wrap: nowrap;

      &__quote {
        width: 100%;
        max-width: unset;
      }
    }
  }
}

@include mq(desktopM) {
  .m-cluster.m-wrapper--expanded {
    .m-summary {
      @include flex(flex-start, center, column);
      flex-wrap: nowrap;

      &__quote {
        width: 100%;
        max-width: unset;
      }
    }
  }
}
</style>
