<template>
  <div
    class="m-dash-el"
    :class="`m-dash-el--${width}`"
    data-tour="tour_m_dash_element"
    @click.stop="select"
  >
    <div class="m-dash-el__heading">
      <div class="m-heading__title">
        <div>
          <h4 class="type--small">
            {{ element.title }}
            <span v-if="element.parent" class="mx-2 h6">|</span>
            {{ element?.parent?.title }}
          </h4>
        </div>
        <div v-if="type == 'editor'" class="m-dash-el__actions">
          <m-icon
            :id="`m_dashboard_el_edit_${snakeCase(element.id)}`"
            :tooltip="editTooltip"
            icon="edit"
            size="small"
            hover="highlight"
            @click="select"
          />
          <m-options
            v-if="!element.parent"
            :id="`m_dashboard_el_opts_${snakeCase(element.id)}`"
            data-tour="tour_m_dash_create_sub_chart"
            variant="primary"
            size="small"
            hover="highlight"
            :position="['left', 'bottom']"
            :options="elOptions"
            class="ml-1"
            @select="selectOpt"
          />
          <m-icon
            v-else
            :id="`m_dashboard_el_close_${snakeCase(element.id)}`"
            icon="close"
            @click="remove"
          />
        </div>
        <div
          v-else-if="type != 'report-editor'"
          class="m-dash-el__actions"
          data-tour="tour_m_dash_el_turn_card"
        >
          <m-icon
            v-if="hasContent"
            :id="`m_dashboard_el_download_${snakeCase(element.id)}`"
            icon="download"
            :tooltip="downloadTooltip"
            @click="exportCSV"
          />
          <m-icon
            v-if="hasContent"
            :id="`m_dashboard_el_articles_${snakeCase(element.id)}`"
            icon="articles"
            :tooltip="seeArticlesTooltip"
            hover="highlight"
            @click="showArticles()"
          />
          <!-- <m-icon
            v-if="type == 'report-editor'"
            :id="`m_dashboard_el_close_${snakeCase(element.id)}`"
            icon="close"
            class="ml-1"
            @click="remove"
          /> -->
        </div>
      </div>
      <div v-if="hasContent" class="m-heading__container">
        <div
          v-for="(el, i) in descriptionList"
          :key="i"
          class="m-heading__description"
        >
          <div class="group">
            <h6 class="type--xsmall">{{ el.label }}</h6>
            <h5
              :id="`m_dashboard_element_${element.id}`"
              :data-tooltip-content="el.value"
              class="type--xsmall"
            >
              {{ el.value }}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <m-message
      :visible="type == 'editor' && showResizeMessage"
      variant="warning"
      :message="t('views.dashboards.scaleCard')"
      size="xsmall"
      class="ma-2"
    />
    <div
      v-show="!isLoadingChart && hasContent"
      class="m-dash-el__content"
      :style="{
        width: '100%',
        height:
          subCharts?.find?.((x) => x.data) && height > 1
            ? `${rowHeight * Math.ceil(Math.min(height, width + 1) / 2) - 62}px`
            : 'calc(100% - 12px)',
      }"
    >
      <canvas ref="canvasRef" class="m-dash-el__canvas"></canvas>
    </div>
    <div
      v-if="!isLoadingChart && !hasContent"
      class="m-dash-el__no-data"
      @click.stop="select"
    >
      <h4 class="type--small">{{ t("views.dashboards.emptyCard_title") }}</h4>
      <h6 v-if="isChildren" class="type--xsmall">
        {{ t("views.dashboards.emptyCard_child_description") }}
      </h6>
      <h6 v-else class="type--xsmall">
        {{ t(`views.dashboards.emptyCard_${route.name}_description`) }}
      </h6>
    </div>
    <div
      v-if="!isLoadingChart && subCharts?.length > 0"
      class="m-dash-el__children"
    >
      <m-hint v-if="displayHints" id="m_dashboard_graph" class="m-hint" />
      <m-dashboard-children
        @click="() => emit('click')"
        :viewId="element.customViewId"
        :elements="subCharts"
        :advancedFilters="element.advancedFilters"
        :type="type"
        :row-height="rowHeight"
        :selectedElementId="selectedElementId"
        :parentLayout="element.layout"
        @select-element="(val) => emit('select', val)"
        @remove-element="(val) => emit('remove', val)"
        @update-layout="(val) => emit('update-layout', val)"
        @chart-data-changed="
          (data, id) => emit('chart-data-changed', data, id, element.id)
        "
      />
    </div>
    <div v-if="!isChart">não é chart</div>
  </div>
  <m-loading
    v-if="isLoadingChart"
    type="spinner"
    size="small"
    class="m-dash-el__loading"
  />
</template>

<script setup>
import { ref, computed, onMounted, watch, unref } from "vue";
import { useRouter, useRoute } from "vue-router";

import { useI18n } from "vue-i18n";
import { useApi } from "@api/api";
import { saveAs } from "file-saver";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  CategoryScale,
  Tooltip,
  Legend,
} from "chart.js";
import {
  ChoroplethController,
  GeoFeature,
  ColorScale,
  ProjectionScale,
} from "chartjs-chart-geo";
import {
  getMapChart,
  getChartConfig,
  resizeMapChart,
} from "@utils/chartConfig";
import { isEqual } from "lodash-es";
import { useWindowSize } from "@vueuse/core";
import TimeFrames from "@utils/enums/timeFrames";
import DateTimeUtils from "@utils/dateTime";
import MHint from "@components/MHint.vue";
import MDashboardChildren from "@components/dashboard/MDashboardChildren.vue";
import MOptions from "@components/MOptions.vue";
import MMessage from "@components/MMessage.vue";
import MIcon from "@components/MIcon.vue";
import MLoading from "@components/MLoading.vue";
import Guid from "@utils/guid";
import { snakeCase } from "lodash-es";
import structuredClone from "@utils/structuredClone";
import { DateTime } from "luxon";
import { debounce } from "lodash-es";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import guid from "@root/utils/guid";
import {
  navigateWithQueryObject,
  useFiltersStore,
} from "@root/store/modules/filters";

//skeletons's imports

// register controller in chart.js and ensure the defaults are set
Chart.register(
  ChoroplethController,
  GeoFeature,
  ColorScale,
  ProjectionScale,
  CategoryScale,

  BarElement,
  ArcElement,
  Legend,
  Tooltip,

  PieController,
  BarController,
  DoughnutController,
  LineController,
  BubbleController,
  PointElement,
  LineElement
);

const props = defineProps({
  element: Object,
  width: Number,
  height: Number,
  dateRestrictionOverride: Object,
  viewId: String,
  rowHeight: { type: Number, default: 228 },
  selectedElementId: { type: String },
  parentFilters: { type: Object, required: false },
  type: { type: String, default: "preview" },
  isChildren: { default: false, type: Boolean },
});

const emit = defineEmits([
  "select",
  "remove",
  "chart-data-changed",
  "create-subchart",
  "select-chart-item",
  "unselect-chart-item",
  "update-layout",
  "update-element-layout",
  "reset-advanced-filters",
]);

const { t } = useI18n();
const { width: windowWidth } = useWindowSize();

const { api } = useApi();
const route = useRoute();
const router = useRouter();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const alertsStore = useAlertsStore();
const filtersStore = useFiltersStore();
const workspacesStore = useWorkspacesStore();
const reportId = computed(() => route.params.reportId); //The dahsboard might be viewable/usable inside a report at any time
const workspace = computed(() => workspacesStore.currentWorkspaceConfig);
const view = computed(() => viewsStore.getViewById(viewId.value));
const viewId = computed(() => {
  if (props.element?.customSettings && props.element?.customViewId)
    return props.element.customViewId;
  return (
    filtersStore.queryFilterViewId ||
    props.viewId ||
    route.params.viewId ||
    workspace.value.baseViewId
  );
});
const computedDateRestriction = computed(() => {
  if (props.element.customSettings)
    return getLocalDateRestriction(props.element.customDate);
  if (props.dateRestrictionOverride) return props.dateRestrictionOverride;
  return filtersStore.dateRestriction;
});

const showResizeMessage = ref(false);
const map = workspace.value.backend?.scenarioId;

const resizeMessageWatcher = (val) => {
  switch (val.type) {
    case "bar":
    case "line":
      if (val.layout.h == 1 && val.maxResults > 9) {
        showResizeMessage.value = true;
      } else showResizeMessage.value = false;
      break;
    case "pie":
    case "doughnut":
      if (
        windowWidth.value < 1440 &&
        val.layout.w == 1 &&
        val.maxResults > 11
      ) {
        showResizeMessage.value = true;
      } else showResizeMessage.value = false;
      if (windowWidth.value > 1440 && val.layout.w == 1 && val.maxResults > 5) {
        showResizeMessage.value = true;
      } else showResizeMessage.value = false;
      break;
    case "bubble":
      if (
        (val.layout.h == 1 && val.maxResults > 5) ||
        (val.layout.h == 2 && val.maxResults > 11)
      ) {
        showResizeMessage.value = true;
      } else {
        showResizeMessage.value = false;
      }
      break;
    case "choropleth":
    default:
      showResizeMessage.value = false;
      break;
  }
};

const resizeMapChartDebounced = debounce(
  (data, newWidth, newHeight, children) => {
    if (props.element?.type === "choropleth") {
      const childrenLength = children?.length ?? 0;
      const computedHeight = childrenLength
        ? Math.ceil(Math.min(newHeight, newWidth + 1) / 2)
        : newHeight;
      resizeMapChart(
        ctx,
        chart,
        newWidth,
        computedHeight,
        map,
        props.element?.filterBy.includes("ISO_3166_alpha-2_country_code"),
        !!childrenLength
      );
    }
    resizeMessageWatcher(props.element);
  },
  30
);

watch(
  () => props.element,
  (val) => {
    resizeMessageWatcher(val);
  },
  { immediate: true }
);

const isLoadingChart = ref(false);
const hasContent = computed(() => {
  if (props.isChildren && !props.parentFilters) return false;
  if (props.element?.type === "choropleth")
    return props.element?.data?.data?.metadata?.values?.length > 0;
  else if (props.element?.type === "bubble")
    return props.element?.data?.data?.data?.datasets[0].data?.length > 0;
  return props.element?.data?.data?.data?.labels?.length > 0;
});

const canvasRef = ref(null);
let chart;
let ctx;
let requestValues;
const showFront = ref(true);
const subCharts = ref(props.element.children);

watch(
  () => [props.width, props.height, subCharts.value],
  ([w, h, sub]) => resizeMapChartDebounced(chart, w, h, sub)
);

watch(
  () => props.element.children,
  (children, oldChildren) => {
    const childrenLength = children?.length ?? 0;
    const oldChildrenLength = oldChildren?.length ?? 0;
    //if (!children?.length) return;
    if (oldChildrenLength != childrenLength) {
      const clone = structuredClone(props.element.layout);
      clone.h = Math.ceil(childrenLength / props.width) + 1;

      if (clone.h != props.element.layout.h) {
        const interval = setInterval(() => {
          // Once again there's to much events firing when a chart is added.
          // So need to wait for all children to have the data loaded and the chart rendered before updating the height of the chart
          const waitingData = subCharts.value.find((x) => !x.data);
          if (!waitingData) {
            emit("update-element-layout", clone);
            clearInterval(interval);
          }
        }, 200);
      }
    }

    subCharts.value = children;
  }
);

const descriptionList = ref([]);
const updateRestrictionList = () => {
  const list = [
    {
      label: t("views.dashboards.articles"),
      value: props.element.data?.totalDocs ?? 0,
    },
  ];

  if (!computedDateRestriction.value.isRelative) {
    let date = computedDateRestriction.value.start.toISODate();
    date += ` - ${computedDateRestriction.value.end.toISODate()}`;
    list.push({
      label: t("views.dashboards.date"),
      value: date,
    });
  } else {
    list.push({
      label: t("views.dashboards.date"),
      value: t(`general.time.${computedDateRestriction.value.timeFrame}`),
    });
  }
  if (props.element.customSettings) {
    const customViewId = props.element.customViewId;
    if (customViewId) {
      const view = viewsStore.getViewById(customViewId);
      list.push({
        label: t("views.dashboards.view"),
        value: view.name,
      });
    }
  } else {
    list.push({
      label: t("views.dashboards.view"),
      value: view.value?.name,
    });
  }
  descriptionList.value = structuredClone(list);
};

const elOptions = computed(() =>
  props.isChildren || !hasContent.value
    ? [{ icon: "delete", value: "remove", label: t("views.dashboards.remove") }]
    : [
        {
          icon: "add",
          value: "create",
          label: t("views.dashboards.createSubChart"),
        },
        {
          icon: "delete",
          value: "remove",
          label: t("views.dashboards.remove"),
        },
      ]
);
const isChart = computed(() => props.element?.type != "Count");

const canvasWidth = computed(
  () =>
    canvasRef.value?.parentElement?.parentElement?.parentElement?.getBoundingClientRect()
      ?.width
);
const canvasHeight = computed(() => {
  let val =
    canvasRef.value?.parentElement?.parentElement?.parentElement?.getBoundingClientRect()
      ?.height - 44;
  if (props.element.children?.length) val = val / 3;
  val = val - 25;
  return val;
});

const showArticles = () => {
  /** @type {import("@root/types").Monitio.URLQueryObject} */
  const query = { ...filtersStore.queryObject }; //Avoid structuredClone() here! It removes needed functions!

  if (props.parentFilters) query.filters = [props.parentFilters];
  if (props.element.customSettings && props.element.customDate) {
    if (props.element.customDate.isRelative) {
      query.dateRestriction = {
        isRelative: true,
        timeFrame: TimeFrames.TryParseFromString(
          props.element.customDate.timeFrame
        ).value,
      };
    } else {
      query.dateRestriction = props.element.customDate;
    }
  } else {
    query.dateRestriction = filtersStore.dateRestriction;
  }

  navigateWithQueryObject(
    route,
    router,
    query,
    "articles",
    {
      viewId: props.element.customViewId ?? viewId.value,
    },
    { from: "dashboards" }
  );
};

const exportCSV = () => {
  const id = guid.NewGuid();
  let name;
  try {
    alertsStore.add({
      id: id,
      type: "toast",
      variant: "load",
      message: t("general.alerts.toast.downloadingCsv", {
        type: props.element.title,
      }),
    });
    const data = requestValues;
    const date = props.element.customSettings
      ? props.element.customDate
      : filtersStore.dateRestriction;

    const csvHead = [
      "Entity Name",
      "Corpora frequency",
      "PMI",
      "Selection Frequency",
    ];
    const csvData = [csvHead].concat(
      data.map((line) => [
        `"${line.key}"`,
        line.corpora_frequency,
        line.pmi,
        line.selection_frequency,
      ])
    );

    //TODO: ADD SUMMARY TO CSV

    //create csv file
    //let universalBOM = "\uFEFF";
    let csvContent = "";
    csvContent += csvData.map((e) => e.join(",")).join("\n");

    let fileName = "";
    if (date.isRelative) {
      const timeframe = DateTimeUtils.getTimeFrame(
        date.timeFrame,
        userStore.timeZone
      );
      fileName += timeframe.start.toISODate();
      fileName += `-${timeframe.end.toISODate()}`;
    } else {
      fileName += date.start.toISODate();
      if (date.end) fileName += `-${date.end.toISODate()}`;
    }

    fileName += ` Chart_${props.element.filterBy}`;

    saveAs(
      new Blob([csvContent], {
        type: "text/csv;charset=utf-8",
      }),
      fileName + ".csv",
      { autoBom: true }
    );
  } catch (error) {
    console.error(error);
  }

  alertsStore.remove(id);
  alertsStore.add({
    id: guid.NewGuid(),
    type: "toast",
    variant: "success",
    message: t("general.alerts.toast.downloadedCsv", {
      type: props.element.title,
    }),
  });
};

const getChartData = async () => {
  if (!props.element.filterBy) {
    console.error(
      `The chart id:{${props.element.id}} of type ${props.element.type} is misconfigured`
    );
    return [];
  }
  let filters = [];
  if (filtersStore.queryObject?.filters)
    filters = filtersStore.queryObject.filters;
  if (props.parentFilters) filters.push(props.parentFilters);
  if (filters.length == 0) filters = null;

  let request;
  switch (props.element.type) {
    case "bar":
      request = await api.analytics.getBarChart(
        props.element,
        viewId.value,
        computedDateRestriction.value,
        props.element.filterBy,
        props.element.maxResults,
        filters,
        reportId.value
      );
      break;
    case "line":
      request = await api.analytics.getBarChart(
        props.element,
        viewId.value,
        computedDateRestriction.value,
        props.element.filterBy,
        props.element.maxResults,
        filters,
        reportId.value
      );
      break;
    case "pie":
    case "doughnut":
      request = await api.analytics.getPieChart(
        props.element.type,
        viewId.value,
        computedDateRestriction.value,
        props.element.filterBy,
        props.element.maxResults,
        filters,
        reportId.value
      );
      break;
    case "bubble":
      request = await api.analytics.getBubblePlot(
        viewId.value,
        computedDateRestriction.value,
        props.element.filterBy,
        props.element.maxResults,
        filters,
        reportId.value
      );
      break;
    case "choropleth":
      request = await api.analytics.getMapChartData(
        viewId.value,
        computedDateRestriction.value,
        props.element.filterBy,
        props.element.maxResults,
        filters,
        reportId.value
      );
      if (request.data) {
        requestValues = request?.data.metadata?.values;
        return {
          data: request.data,
          totalDocs: request?.data?.metadata?.metadata?.corporaCount,
        };
      } else return [];
  }

  const data = request.data?.config;
  if (data) {
    requestValues = request?.data?.values;
    return { data, totalDocs: request?.data?.metadata?.corporaCount };
  }
  return [];
};

/**
 * @param {import("@root/types.api.local").MonitioAPI.DateRestriction} localDateRestriction
 */
const getLocalDateRestriction = (localDateRestriction) => {
  if (!localDateRestriction) return null;
  if (localDateRestriction.isRelative) return localDateRestriction;

  return {
    isRelative: false,
    start: DateTime.isDateTime(localDateRestriction.start)
      ? localDateRestriction.start
      : DateTimeUtils.parseFromISO(localDateRestriction.start),
    end: DateTime.isDateTime(localDateRestriction.end)
      ? localDateRestriction.end
      : DateTimeUtils.parseFromISO(localDateRestriction.end),
  };
};

const createChart = (config) => {
  if (config) {
    getChartConfig(props.element, props.width, props.height, config.data);

    if (props.element?.type === "choropleth") {
      const childrenLength = props.element.children?.length ?? 0;
      const computedHeight = childrenLength
        ? Math.ceil(Math.min(props.height, props.width + 1) / 2)
        : props.height;
      resizeMapChart(
        ctx,
        config.data,
        props.width,
        computedHeight,
        map,
        props.element?.filterBy?.includes("ISO_3166_alpha-2_country_code"),
        !!childrenLength
      );
    }

    chart = new Chart(ctx, config.data);
    addChartEvents();
  }
};

const addChartEvents = () => {
  if (!ctx || !chart || !canvasRef.value) {
    console.debug("canvasRef is null. Not adding event listeners on chart");
    return;
  }
  canvasRef.value.onclick = selectPropertyFromChartClick;
};

const selectPropertyFromChartClick = (event) => {
  //if (props.type != "editor") return;

  const points = chart.getElementsAtEventForMode(
    event,
    "nearest",
    { intersect: true },
    true
  );

  if (points.length <= 0) return;
  const element = points[0];

  let selectedLabel;
  if (props.element?.type === "bubble") {
    selectedLabel =
      chart.data.datasets[element.datasetIndex].data[element.index].label;
  } else {
    selectedLabel = chart.data.labels[element.index];
  }

  const selectedKey =
    chart.data.keys[selectedLabel.toLowerCase()] ?? selectedLabel;

  if (
    props.element.advancedFilters?.facets?.find((x) =>
      x.query.find((f) => f.value == selectedKey)
    )
  ) {
    emit(
      "unselect-chart-item",
      { key: selectedKey, label: selectedLabel },
      props.element.id
    );
  } else {
    emit(
      "select-chart-item",
      { key: selectedKey, label: selectedLabel },
      props.element.id
    );
  }
};
/**
 * Handle the chart colors according to the selected items/filters,
 * the initial colors are defined once the user clicks the chart for the 1st time
 */
watch(
  () => props.element.advancedFilters,
  /** @param {import("@/api").MonitioAPI.FrontendFiltersGroup} advancedFilters */
  (advancedFilters) => {
    if (!advancedFilters) return;
    colorizeChart(advancedFilters);
    chart.update();
  },
  { deep: true }
);

let initialColors;
/**@description function that colorizes the chart according the the advanced filters */
const colorizeChart = (advancedFilters) => {
  if (!advancedFilters) return;
  if (!initialColors) {
    initialColors = chart.config.data.datasets.map((x) => {
      if (typeof x.backgroundColor == "string") {
        return x.data.map(() => x.backgroundColor);
      } else {
        return x.backgroundColor;
      }
    });
  }

  const gray = "#EDEDED";
  const blue = initialColors[0]?.find((x) => x != gray) ?? "#0046c7";
  if (!initialColors) initialColors = [[blue]];

  const filters = advancedFilters;
  const facet = filters?.facets?.find((x) => x.value == props.element.filterBy);
  const labels =
    facet?.query?.filter((x) => !x.operator).map((x) => x.label) || [];
  const datasetIndex = 0; //For now assume there's only one dataset
  let activeIndexes = [];

  if (props.element?.type === "bubble") {
    activeIndexes = chart.data.datasets[datasetIndex].data
      .map((x, i) => (labels.includes(x.label) ? i : ""))
      .filter((x) => typeof x == "number");
  } else
    activeIndexes = chart.data.labels
      .map((x, i) => (labels.includes(x) ? i : ""))
      .filter((x) => typeof x == "number");

  if (activeIndexes.length > 0 && props.element?.type !== "choropleth") {
    chart.data.datasets[datasetIndex].backgroundColor = initialColors[
      datasetIndex
    ].map((_c, i) => {
      if (activeIndexes.includes(i)) return blue;
      else return gray;
    });
  } else {
    if (props.element?.type === "choropleth") {
      if (activeIndexes.length === 0) {
        delete chart.data.datasets[datasetIndex].backgroundColor;
      } else {
        const data = chart.data.datasets[datasetIndex].data;
        const colors = [];
        data.forEach((el, index) => {
          if (activeIndexes.includes(index)) colors.push(blue);
          else {
            if (el.value > 0) colors.push(gray);
            else colors.push("");
          }
        });
        chart.data.datasets[datasetIndex].backgroundColor = colors;
      }
    } else
      chart.data.datasets[datasetIndex].backgroundColor = initialColors[
        datasetIndex
      ].map((x) => blue);
  }
};

const turnCard = () => {
  showFront.value = !showFront.value;
};

const selectOpt = (opt) => {
  if (opt == "create") {
    const newDashboardId = Guid.NewGuid();
    emit("create-subchart", props.element.id, {
      type: props.element.type,
      title: "SubChart of " + props.element.title,
      subchart: true,
      id: newDashboardId,
      layout: {
        x: 0, // not goonna work
        y: 0, // not goonna work
        w: 1,
        h: 1,
        i: newDashboardId,
        static: false,
      },
      filterBy: props.element.filterBy,
      maxResults: 5,
      customSettings: props.element.customSettings,
      customDate: props.element.customDate,
      customViewId: props.element.customViewId,
    });
  } else if (opt == "remove") {
    emit("remove", props.element?.id);
  }
};

const select = () => {
  emit("click");
  emit("select", props.element?.id);
};

const remove = () => {
  emit("remove", props.element?.id);
};

const canChangeType = (oldType, newType) => {
  if (oldType == "pie" && newType == "doughnut") return true;
  else if (oldType == "doughnut" && newType == "pie") return true;
  else if (oldType == "bar" && newType == "line") return true;
  else if (oldType == "line" && newType == "bar") return true;
  else if (oldType == "bar" && newType == "overview") return true;
  else if (oldType == "line" && newType == "overview") return true;
  else return false;
};

const getMapChartFilters = () => {
  const userConfig = userStore.config;
  const availablePropertyTypes = userConfig.propertyTypeSettings.facets?.[
    workspace.value.id
  ]
    .filter((x) => x.active)
    .sort((a, b) => a.rank - b.rank);

  const filters = availablePropertyTypes
    .filter((x) => x.mapEnabled)
    .map((x) => ({
      value: x.searchKey,
      label: t(`general.facets.${x.uiKey}`),
    }));
  return filters ?? [];
};

onMounted(async () => {
  let config;
  isLoadingChart.value = true;
  // emit full config to element if not chopMDashropleth
  config = await getChartData();
  // Im mapchart we are emmiting the metadata and topojson type
  emit("chart-data-changed", config, props.element.id);
  updateRestrictionList();
  isLoadingChart.value = false;

  ctx = canvasRef.value?.getContext("2d");
  if (isChart.value && ctx) {
    if (props.element?.type === "choropleth") {
      config = await getMapChart(api, props.element.data.data);
    }
    createChart(config);
  }

  if (props.element.advancedFilters) {
    colorizeChart(props.element.advancedFilters);
    chart.update();
  }
  updateRestrictionList();
  isLoadingChart.value = false;
});

const newData = ref(false);
watch(
  () => [
    props.element,
    viewId.value,
    computedDateRestriction.value,
    filtersStore.queryObject?.filters,
    JSON.stringify(props.parentFilters),
  ],
  async (
    [newElement, newViewId, newDate, newFilters, newParentFilters],
    [oldElement, oldViewId, oldDate, oldFilters, oldParentFilters]
  ) => {
    let config;
    if (
      (newViewId != oldViewId && !oldElement.customSettings) || // If the selected view is diferent and the dashboard doenst have a customView selected refresh
      newElement.filterBy != oldElement.filterBy || // If the filters are diferent refresh
      newElement.maxResults != oldElement.maxResults || // If the user changed the number of max results refresh
      oldParentFilters != newParentFilters || //If the filters of the parent changed refresh
      newElement.showMovingAverage != oldElement.showMovingAverage || // If user changed moving average property refresh
      newElement.averageDays != oldElement.averageDays || // If user changed average days property refresh
      newElement.variant != oldElement.variant || // If user changed variant property refresh
      newElement.customViewId != oldElement.customViewId ||
      !isEqual(newDate, oldDate) ||
      !isEqual(newElement.customDate, oldElement.customDate) ||
      !isEqual(newFilters, oldFilters)
    ) {
      isLoadingChart.value = true;

      // Reset the advancedFilters if the property that changed was the filterBy, else they will accumulate to many values
      if (newElement.filterBy != oldElement.filterBy)
        emit("reset-advanced-filters", props.element.id);

      config = await getChartData();
      emit("chart-data-changed", config, props.element.id);
      updateRestrictionList();
      if (config?.data?.data) newData.value = true; //Little hack because of issues in the watch
      // If the chart comes from a state where it has no data it needs recreation
      const forceRedraw =
        props.element?.data?.data?.datasets?.length == 0 &&
        config?.data?.data?.datasets?.length > 0
          ? true
          : false;

      if (forceRedraw && isChart.value && ctx) {
        // If the chart comes from a state where it has no data it needs recreation
        chart.destroy();
        ctx = canvasRef.value?.getContext("2d");
        createChart(config);
        newData.value = false;
      }
      if (chart) {
        chart.destroy();
        //If the new type equals to map we need to change the filter
        if (props.element?.type === "choropleth") {
          const filters = getMapChartFilters();
          if (
            !filters?.map((x) => x.value)?.includes?.(props.element.filterBy)
          ) {
            // eslint-disable-next-line vue/no-mutating-props
            props.element.filterBy = filters[0].value;
          }
          config = await getChartData();
          emit("chart-data-changed", config, props.element.id);
          config = await getMapChart(api, props.element.data.data);
        }
        createChart(config);
      }
      isLoadingChart.value = false;
    }

    // If the only thing changed was the type, only change the chart config. No need to request a new one;
    else if (
      newElement.type != oldElement.type &&
      isEqual(newElement.data, oldElement.data) &&
      canChangeType(oldElement.type, newElement.type)
    ) {
      const newConfig = newElement.data;
      newConfig.type = newElement.type;
      chart.config.type = newElement.type;
      // Small detail for line chart
      if (oldElement.type === "bar") {
        chart.config.data.datasets[0].borderWidth = 1;
      }
      // No need to destroy, we can just update the chart with the new config;
      chart.update();
      // Emit the datatype changed
      emit("chart-data-changed", newConfig, props.element.id);
    } else if (newElement.type != oldElement.type) {
      if (newElement.children?.length) {
        /* newElement.advancedFilters ??= { facets: [] };
        newElement.advancedFilters.facets = [];
        delete newElement.children[0].data?.data?.labels; */
      }
      isLoadingChart.value = true;
      // Destroy the previous instance
      chart.destroy();
      //If the new type equals to map we need to change the filter
      if (newElement.type === "choropleth") {
        const filters = getMapChartFilters();
        if (!filters.length) {
          hasContent.value = false;
          isLoadingChart.value = false;
          return;
        }
        // eslint-disable-next-line vue/no-mutating-props
        props.element.filterBy = filters[0].value;
        config = await getChartData();
        emit("chart-data-changed", config, props.element.id);
        config = await getMapChart(api, props.element.data.data);
      } else {
        config = await getChartData();
        emit("chart-data-changed", config, props.element.id);
      }

      ctx = canvasRef.value?.getContext("2d");
      if (isChart.value && ctx) {
        createChart(config);
      }

      updateRestrictionList();
      isLoadingChart.value = false;
    }
  },
  { deep: true }
);
const displayHints = computed(() => {
  return (
    !userStore.checkCompletedTour("dashboard") &&
    userStore.ongoingTour.currentStep == 7
  );
});

const downloadTooltip = computed(() => {
  return {
    content: t("views.dashboards.chart_download"),
    position: "dynamic",
  };
});

const seeArticlesTooltip = computed(() => {
  return {
    content: t("views.dashboards.chart_seeArticles"),
    position: "dynamic",
  };
});

const editTooltip = computed(() => {
  return {
    content: t("views.dashboards.chart_edit"),
    position: "bottom-left",
  };
});
</script>

<style scoped lang="scss">
.m-dash-el {
  width: 100%;
  height: 100%;
  padding: $spacing-3 $spacing-0 $spacing-4;
  border: 1px solid color($pri-light);
  @include round-corners($spacing-1);
  position: relative;
  background-color: color($white);
  overflow: hidden;

  &--1 {
    .m-dash-el__heading h5 {
      max-width: 100%;
      @include one-line-ellipsis;
    }
  }

  &__heading {
    @include flex(space-between, center, column);
    padding: $spacing-0 $spacing-3 $spacing-0 $spacing-4;
    h4 {
      @include ellipsis;
    }
  }
  .m-heading {
    &__title {
      width: 100%;
      height: 100%;
      @include flex(space-between, center, row);
    }

    &__container {
      width: 100%;
      @include flex(left, center, row);
      gap: $spacing-4;
    }

    &__description {
      .group {
        @include flex(left, center, row);
        gap: $spacing-2;
      }
    }
  }

  :deep(.m-message) {
    width: calc(100% - $spacing-4);
    position: absolute;
  }

  &__canvas {
    transform: translateY(-8px);
  }

  :deep(.m-options__dropdown) {
    right: 0;
  }

  &__loading {
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: $z-popover;
  }

  &__actions {
    @include flex(flex-end, center, row);
  }

  &__no-data {
    width: 100%;
    height: 100%;
    @include flex(center, center, column);
  }

  .m-hint {
    transform: translate(-21px, 49px) !important;
  }
}
</style>
@root/utils/
